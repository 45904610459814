import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { ReactComponent as ExclamationMark } from '../../assets/icons/exclamationMark.svg'

import { Cross, CrossContainer, ExclamationMarkButton, Xcontainer, Xtext } from './AnimatedFail.style'

const AnimatedFail = () => {
  const [showX, setShowX] = useState(false)

  useEffect(() => {
    setShowX(true)
  }, [])

  const ExclamationMarkButtonClass = clsx('Xspin Xcircle Xbutton', {
    focusXspin: showX,
  })

  return (
    <Xcontainer>
      <ExclamationMarkButton autoFocus className={ExclamationMarkButtonClass}>
        <CrossContainer>
          <Cross>
            <ExclamationMark />
          </Cross>
        </CrossContainer>
      </ExclamationMarkButton>
      <Xtext>Order Declined</Xtext>
    </Xcontainer>
  )
}

export default AnimatedFail
