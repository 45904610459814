import { styled } from '@mui/system'
import { Grid } from '@mui/material'

export const ExposureContainer = styled(Grid)(({ theme }) => ({
  width: 299,
  border: `2px solid ${theme.palette.blue.blue7}`,
  backgroundColor: theme.palette.green.green3,
  color: theme.palette.white.white1,
  padding: '0 24px',
  height: 40,
  borderRadius: 4,
  justifyContent: 'space-between',
  alignItems: 'center',
}))
