import React, { Fragment } from 'react'
import { WidgetHeaderStyled, TabStyled, TabStyledSelect, Tabs, BoxIcons } from './widgetHeaderMulti.style'
import { CustomFilterButton, CustomReplayButton, CustomStatementButton, WidgetHeaderDivider, IconButton } from '../WidgetHeader/WidgetHeader.style'

function WidgetHeaderMulti(props) {
  const { titles, has_reload, refreshData, setBlotter, setIsOpenDrawerFilter, isOpenDrawerFilter, setIsOpenStatement, isOpenStatement } = props
  const [value, setValue] = React.useState(titles[0])

  const handleChange = (event, newValue) => {
    setBlotter(newValue)
    setValue(newValue)
  }

  const handelFilterIcon = () => {
    setIsOpenDrawerFilter(!isOpenDrawerFilter)
    setIsOpenStatement(false)
  }

  const handleStatementIcon = () => {
    setIsOpenStatement(!isOpenStatement)
    setIsOpenDrawerFilter(false)

  }

  return (
    <>
      <WidgetHeaderStyled>
        <Tabs value={value} onChange={handleChange}>
          {titles.map((title, index) => (title === value ? <TabStyledSelect key={index} label={title} value={title} /> : <TabStyled key={index} label={title} value={title} />))}
        </Tabs>
        <BoxIcons>
          {has_reload && (
            <IconButton onClick={refreshData}>
              <CustomReplayButton />
            </IconButton>
          )}
          {titles[0] === value && (
            <Fragment>
              <IconButton onClick={handelFilterIcon}>
                <CustomFilterButton isopendrawerfilter={String(isOpenDrawerFilter)} />
              </IconButton>
              <IconButton onClick={handleStatementIcon}>
                <CustomStatementButton isopenstatement={String(isOpenStatement)} />
              </IconButton>
            </Fragment>
          )}
        </BoxIcons>
      </WidgetHeaderStyled>
      <WidgetHeaderDivider />
    </>
  )
}

export default WidgetHeaderMulti
