import { createSlice } from '@reduxjs/toolkit'
import { ENDPOINTS, MODALS, REST_GATEWAY_URL } from '../../../utils/constants'
import axios from 'axios'
import { setAuthToken } from '../../../utils/systemHandler'
import workerInstance from '../../../services'
import { setModal } from '../Modal/modalSlice'
import { handelNotifications } from '../Notification/notificationSlice'
const initial2fa = {
  loader: false,
  token: null,
  qrImage: null,
  secret: null,
  loadingIndicator2FA: false,
  digitAnswer: null,
  error: '',
}

const initialState = {
  token: sessionStorage.getItem('TOKEN') || null,
  isAuthenticated: false,
  loadingIndicator: false,
  loadingIndicatorFullScreen: false,
  userContent: {},
  twoFactAuth: initial2fa,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload.isAuthenticated
    },
    set2FaToken: (state, action) => {
      state.twoFactAuth.token = action.payload.token
      if (action.payload.qrImage) {
        state.twoFactAuth.qrImage = action.payload.qrImage
      }
      if (action.payload.secret) {
        state.twoFactAuth.secret = action.payload.secret
      }
    },
    setQrImage: (state, action) => {
      state.twoFactAuth.qrImage = action.payload.qrImage
    },
    setSecret: (state, action) => {
      state.twoFactAuth.secret = action.payload.secret
    },
    setUserAuthenticated: (state, action) => {
      state.isAuthenticated = true
      state.token = action.payload.token
    },
    set2FAError: (state, action) => {
      state.twoFactAuth.error = action.payload.message
    },
    setLoader: (state, action) => {
      state.twoFactAuth.loader = action.payload
    },
  },
})

export const logout = (navigate) => () => {
  workerInstance.WebSocketPricesInstance.closeWS()
  sessionStorage.clear()
  navigate('/login')
}

export const login2FA = (sixDigits, token, navigate) => async (dispatch) => {
  const url = `${REST_GATEWAY_URL}${ENDPOINTS.AUTH_REGISTER}`
  try {
    dispatch(setLoader(true))
    const result = await axios.put(url, sixDigits, {
      headers: { Authorization: `Bearer ${token}` },
    })
    dispatch(setUserAuthenticated(result.data))
    sessionStorage.setItem('TOKEN', result.data.token)
    setAuthToken(result.data.token)
    dispatch(setLoader(false))
    dispatch(setModal({ type: MODALS.MODAL_2FA, isOpen: false }))
    dispatch(
      handelNotifications({
        id: Date.now(),
        message: '2FA made successfully',
        type: 'success',
      })
    )
    navigate('/')
  } catch (error) {
    dispatch(setLoader(false))
    dispatch(set2FAError({ message: `wrong six digits` }))
  }
}

export const { set2FaToken, setUserAuthenticated, setLoader, setQrImage, setSecret, set2FAError } = authSlice.actions
export default authSlice.reducer
