import React from 'react'
import { getBaseCurrency, getQuoteCurrency, numberWithCommas } from '../../../utils/helperFunctions'
import clsx from 'clsx'
import { GreyLabel, StyledPriceBySide, SpanBySideColor, Grid } from './PriceBySide.style'

const PriceBySide = ({ isRequesting, priceQuote, selectedType, selectedProduct, side, quantityQuote }) => {
  const colorBySide = clsx({
    buy: side === 'BUY',
    sell: side === 'SELL',
  })

  return (
    <StyledPriceBySide container>
      {isRequesting && (
        <>
          {selectedType === 'quantity' ? (
            <>
              {priceQuote > 0 && (
                <>
                  <Grid item xs={12}>
                    <GreyLabel>Price : </GreyLabel>
                    <SpanBySideColor className={colorBySide}>
                      {numberWithCommas(priceQuote)} {getQuoteCurrency(selectedProduct)}
                    </SpanBySideColor>
                  </Grid>
                </>
              )}
            </>
          ) : (
            <>
              {priceQuote > 0 && quantityQuote && (
                <>
                  <Grid item xs={12}>
                    <GreyLabel> Price : </GreyLabel>
                    <SpanBySideColor className={colorBySide}>
                      {numberWithCommas(priceQuote)} {getQuoteCurrency(selectedProduct)}
                    </SpanBySideColor>
                  </Grid>

                  <Grid item xs={12}>
                    <GreyLabel> Quantity : </GreyLabel>
                    <SpanBySideColor className={colorBySide}>
                      {numberWithCommas(quantityQuote)} {getBaseCurrency(selectedProduct)}
                    </SpanBySideColor>
                  </Grid>
                </>
              )}
            </>
          )}
        </>
      )}
    </StyledPriceBySide>
  )
}

export default PriceBySide
