import { styled } from '@mui/system'
import { TableCell, TableRow, TableContainer, Typography } from '@mui/material/'
import LensIcon from '@mui/icons-material/Lens'

export const CustomTableRowHeader = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.black.black3,
  color: theme.palette.grey.grey1,
}))

export const CustomTableContainer = styled(TableContainer)(({ theme, height, isopendrawerfilter }) => {
  let width = '100%'
  if (isopendrawerfilter === 'true') {
    if (theme.breakpoints.up('lg')) {
      width = '90%'
    } else if (theme.breakpoints.only('lg')) {
      width = '80%'
    } else if (theme.breakpoints.only('md')) {
      width = '50%'
    } else if (theme.breakpoints.down('md')) {
      width = '30%'
    }
  }
  return {
    width,
    height: `calc(${height} - 46px)`,
    backgroundColor: theme.palette.primary.main,
    overflowX: 'auto',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px',
      height: '6px',
    },
    '&::-webkit-scrollbar-track': {
      background: theme.palette.black.black1,
      width: '6px',
      height: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.background,
      borderRadius: '4px',
      width: '6px',
      height: '6px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: theme.palette.primary.background,
      width: '6px',
      height: '6px',
    },
    '&::-webkit-scrollbar-thumb:horizontal': {
      width: '6px',
      height: '6px',
    },
    '&::-webkit-scrollbar-thumb:vertical': {
      width: '6px',
      height: '6px',
    },
  }
})

export const CustomTableCellHeader = styled(TableCell)(({ theme }) => ({
  color: theme.palette.grey.grey1,
  borderBottom: `1px solid ${theme.palette.black.black1}`,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}))

export const CustomTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.black.black3,
  color: theme.palette.grey.grey1,
  '&:hover': {
    backgroundColor: theme.palette.blue.blue5,
  },
}))

export const StyledTableCell = styled(TableCell)(({ theme, color }) => ({
  borderBottom: `1px solid ${theme.palette.black.black1}`,
  color: color === undefined ? theme.palette.white.white1 : color,
  fontSize: theme.typography.fontSize,
  width: 'fit-content',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  '&:hover': {
    overflow: 'visible',
  },
}))

export const StyledType = styled(Typography)(({ theme, order_type }) => {
  let backgroundColor = theme.palette.purple.purple4
  let color = theme.palette.purple.purple5

  switch (order_type) {
    case 'trade':
    case 'limit':
      backgroundColor = theme.palette.purple.purple4
      color = theme.palette.purple.purple5
      break
    case 'settlement':
    case 'market_nominal':
      backgroundColor = theme.palette.purple.purple3
      color = theme.palette.blue.blue1
      break
    case 'market':
      backgroundColor = theme.palette.green.green3
      color = theme.palette.blue.blue6
      break
    default:
      backgroundColor = theme.palette.purple.purple4
      color = theme.palette.purple.purple5
  }
  return {
    color,
    backgroundColor,
    textTransform: 'capitalize',
    fontSize: theme.typography.fontSizes.m,
    fontWeight: '550',
    width: 'fit-content',
    padding: '2px 5px',
    marginLeft: 'auto',
    marginRight: 'auto',
    borderBottom: `1px solid ${theme.palette.black.black1}`,
    borderRadius: '4px',
  }
})

export const StyledIcon = styled(LensIcon)(({ theme, status }) => {
  let color
  switch (status.toLowerCase()) {
    case 'filled':
      color = theme.palette.green.green1
      break
    case 'pending':
      color = theme.palette.yellow.yellow1
      break
    case 'canceled':
      color = theme.palette.red.red1
      break
    case 'rejected':
      color = theme.palette.red.red1
      break
    default:
      color = theme.palette.green.green1
  }
  return {
    width: '8px',
    height: '8px',
    color: color,
  }
})
