import * as React from 'react'
import { useTheme } from '@mui/material/styles'
import { Divider, Drawer, Box } from '@mui/material'

export default function DrawerFilters({ children, isOpenDrawerFilter }) {
  const theme = useTheme()
  if (isOpenDrawerFilter) {
    return (
      <Box>
        <Drawer
          sx={{
            boxSizing: 'border-box',
            '& .MuiDrawer-paper': {
              backgroundColor: theme.palette.black.black3,
              marginTop: '34px',
              [theme.breakpoints.up('lg')]: {
                width: '25%',
                height: 'calc(100% - 34px)',
              },
              [theme.breakpoints.down('lg')]: {
                width: '40%',
                height: 'calc(100% - 34px)',
              },
              [theme.breakpoints.down('md')]: {
                width: '50%',
                height: 'calc(100% - 34px)',
              },
              [theme.breakpoints.down('sm')]: {
                width: '70%',
                height: 'calc(100% - 34px)',
              },
            },
          }}
          variant="persistent"
          anchor="right"
          open={isOpenDrawerFilter}
        >
          <Box> {children} </Box>

          <Divider />
        </Drawer>
      </Box>
    )
  } else return <></>
}
