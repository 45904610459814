import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  notifications: [],
}

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    addNotification: (state, action) => {
      state.notifications.push(action.payload)
    },
    removeNotification: (state, action) => {
      state.notifications = state.notifications.filter((notification) => notification.id === action.payload.id)
    },
  },
})

export const handelNotifications = (notification) => (dispatch) => {
  dispatch(addNotification(notification))
  setTimeout(() => {
    dispatch(removeNotification(notification.id))
  }, 3000)
}

export default notificationSlice.reducer
export const { addNotification, removeNotification } = notificationSlice.actions
