import React from 'react'
import '../RFQ.style'
import { InputAdornment } from '@mui/material'
import clsx from 'clsx'
import { getBaseCurrency, getQuoteCurrency } from '../../../utils/helperFunctions'
import { StyledCustomButton } from '../../SharedComponents/Buttons/CustomButton'
// import { TextField } from './InputWithProductButton.style'
import CustomTextField from '../../SharedComponents/TextFields/CustomTextField'

const InputWithProductButton = ({ handleTypeChange, quantity, selectedType, selectedProduct, handleChangeQuantity, isRequesting, quantityError }) => {
  const baseCurrencyButton = clsx({
    baseCurrencyButton: selectedType === 'quantity' && !isRequesting,
    isRequestCurrency: selectedType === 'quantity' && isRequesting,
    naturalButton: selectedType !== 'quantity',
  })

  const quoteCurrencyButton = clsx({
    quoteCurrencyButton: selectedType === 'nominal' && !isRequesting,
    isRequestCurrency: selectedType === 'nominal' && isRequesting,
    naturalButton: selectedType !== 'nominal',
  })
  const RfqQtyTextField = clsx('disableArrows', {
    isRequesting: 'baseColor',
  })

  return (
    <CustomTextField
      fullWidth
      error={quantityError}
      disabled={isRequesting}
      className={RfqQtyTextField}
      // className="disableArrows"
      type="number"
      variant="outlined"
      value={quantity}
      onChange={(e) => handleChangeQuantity(e.target.value)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" style={{ marginRight: '-13px' }}>
            <StyledCustomButton style={{ minWidth: '50px' }} value="quantity" onClick={handleTypeChange} className={baseCurrencyButton} disabled={isRequesting}>
              {getBaseCurrency(selectedProduct)}
            </StyledCustomButton>

            <StyledCustomButton style={{ minWidth: '50px' }} value="nominal" onClick={handleTypeChange} className={quoteCurrencyButton} disabled={isRequesting}>
              {getQuoteCurrency(selectedProduct)}
            </StyledCustomButton>
          </InputAdornment>
        ),
      }}
    />
  )
}

export default InputWithProductButton
