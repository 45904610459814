import { styled } from '@mui/system'
import { Typography, Paper, Button } from '@mui/material'

export const ModalContent = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '354px',
  background: `${theme.palette.black.black2} 0% 0% no-repeat padding-box`,
  paddingLeft: '40px',
  paddingRight: '40px',
  paddingBottom: '24px',
  paddingTop: '34px',
}))

export const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.white.white1,
  textAlign: 'center',
  fontSize: theme.typography.fontSizes.m,
  marginBottom: '20px',
}))

export const Article = styled(Typography)(({ theme }) => ({
  color: theme.palette.white.white1,
  textAlign: 'center',
  fontSize: theme.typography.fontSizes.m,
  marginBottom: '33px',
}))

export const ButtonStayConnected = styled(Button)(({ theme }) => ({
  width: '194px',
  marginRight: 'auto',
  marginLeft: 'auto',
  height: '40px',
  textAlign: 'center',
  color: theme.palette.black.black3,
  backgroundColor: theme.palette.blue.blue6,
  fontWeight: 600,
  fontSize: theme.typography.fontSizes.lg,
  '&:hover': {
    backgroundColor: theme.palette.blue.hover,
    color: theme.palette.black.black3,
  },
}))
