import { Button } from '@mui/base'
import { Typography } from '@mui/material'
import { styled } from '@mui/system'

export const ExclamationMarkButton = styled(`button`)(({ theme }) => ({
  '&.Xspin': {
    height: 48,
    width: 48,
    '&::before': {
      border: '2px solid transparent',
    },
    '&::before, &::after': {
      top: 0,
      left: 0,
    },
    '&::after': {
      border: '0 solid transparent',
    },
  },
  '&.Xcircle': {
    borderRadius: '100%',
    boxShadow: 'none',
    '&::before, &::after': {
      borderRadius: '100%',
    },
  },
  '&.Xbutton': {
    border: 0,
    position: 'relative',
    outline: 'none',
    backgroundColor: 'transparent',
    // transform: 'rotate(90deg)',
    zIndex: 0,
    '&::before, &::after': {
      boxSizing: 'inherit',
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
    },
  },
  '&.focusXspin': {
    '&::before': {
      borderTopColor: theme.palette.red.red4,
      borderRightColor: theme.palette.red.red4,
      borderBottomColor: theme.palette.red.red4,
      transition: 'border-top-color 0.15s linear, border-right-color 0.15s linear 0.10s, border-bottom-color 0.15s linear 0.20s',
    },
    '&::after': {
      borderTop: `2px solid ${theme.palette.red.red4}`,
      borderLeftWidth: 2,
      borderRightWidth: 2,
      transform: 'rotate(270deg)',
      transition: 'transform 0.4s linear 0s, border-left-width 0s linear 0.35s, -webkit-transform 0.4s linear 0s',
    },
  },
}))

export const Xcontainer = styled(`div`)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  color: theme.palette.red.red4,
  fontSize: theme.typography.fontSize,
}))

export const CrossContainer = styled(`div`)({
  width: 37,
  height: 35,
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  alignContent: 'center',
  paddingTop: 13,
  paddingRight: 7,
})
export const Cross = styled(`div`)({
  /* transform: rotate(-55deg); */
  position: 'absolute',
  zIndex: 1,
  marginBottom: 10,
  animation: '1s ease cross',
  /* animation-delay: 0.5s; */
  overflow: 'hidden',
  paddingBottom: 0,
  /* background-color: pink; */
  /* opacity: 0; */
})

export const Xtext = styled(Typography)(({ theme }) => ({
  marginTop: 5,
  fontSize: theme.typography.fontSizes.lg,
}))

export const focusXspin = styled(Button)(({ theme }) => ({
  '&::before': {
    borderTopColor: theme.palette.red.red4,
    borderRightColor: theme.palette.red.red4,
    borderBottomColor: theme.palette.red.red4,
    transition: 'border-top-color 0.15s linear, border-right-color 0.15s linear 0.10s, border-bottom-color 0.15s linear 0.20s',
  },
  '&::after': {
    borderTop: `2px solid ${theme.palette.red.red4}`,
    borderLeftWidth: 2,
    borderRightWidth: 2,
    transform: 'rotate(270deg)',
    transition: 'transform 0.4s linear 0s, border-left-width 0s linear 0.35s, -webkit-transform 0.4s linear 0s',
  },
}))
