import React, { useEffect, useState } from 'react'
import { isInteger } from 'lodash'
import { Typography } from './ShortText.style'

function ShortText(prop) {
  const { text, char, color = 'grey' } = prop
  const [textState, setText] = useState({ number: '', afterPoint: '' })
  const [isHover, setIsHover] = useState(false)

  useEffect(() => {
    if (!isHover) {
      numberAnimate()
    } else {
      handelHover()
    }
  }, [prop])

  const numberAnimate = () => {
    setIsHover(false)
    let number,
      afterPoint = ''
    if (String(text).includes('.')) {
      const textAfterPoint = String(text).split('.')[1]
      if (textAfterPoint.length === 1) {
        number = String(text)
        afterPoint = '0'
      } else if (textAfterPoint.length === 2) {
        number = String(text)
      } else if (textAfterPoint.length > 2) {
        number = String(text)
          .substring(0, String(text).indexOf('.') + 3)
          .concat(' ', char)
      }
    } else {
      number = String(text)
      afterPoint = '.00'
    }
    setText({ number, afterPoint })
  }

  const handelHover = () => {
    setIsHover(true)
    let number = String(text),
      afterPoint = ''
    const textAfterPoint = String(text).split('.')[1]
    if (isInteger(text)) {
      number = String(text)
      afterPoint = '.00'
    } else if (number.includes('.') && textAfterPoint.length === 1) {
      number = String(text)
      afterPoint = `0`
    }
    setText({ number, afterPoint })
  }

  if (text === '') {
    return <></>
  }

  return (
    <Typography component={'div'} style={{ width: '100px', display: 'inline-block' }} onMouseEnter={handelHover} onMouseLeave={numberAnimate}>
      <Typography style={{ fontWeight: '550' }} component={'span'}>
        {textState.number}
      </Typography>
      {textState.afterPoint !== '' && (
        <Typography component={'span'} style={{ color, fontWeight: '550' }}>
          {textState.afterPoint.length === 0 ? `.${textState.afterPoint}` : textState.afterPoint}
        </Typography>
      )}
    </Typography>
  )
}

export default ShortText
