import { TextField } from '@mui/material'
import React, { useMemo } from 'react'
import { Autocomplete, StyleAutoCompleteMenuItem, StyledArrowIcon, StyledListBox, StyledPaper, StyledSearchIcon } from './CustomAutoComplete.style'
import { v4 as uuidv4 } from 'uuid'

const CustomAutoComplete = ({ items, selectedItem, handleItemChange, isRequesting = false, label }) => {
  const uuid = uuidv4()

  const itemsFromMemo = useMemo(() => items.map((item) => ({ ...item, label: item.symbol })), [items])

  return (
    <Autocomplete
      id={uuid}
      disabled={isRequesting}
      popupIcon={label === 'Instrument' ? null : <StyledArrowIcon />}
      forcePopupIcon={false}
      disableClearable={true}
      options={itemsFromMemo}
      value={{ label: selectedItem }}
      isOptionEqualToValue={(option, value) => option.label === value.label || value.label === ''}
      onChange={(event, newValue) => {
        handleItemChange(newValue)
      }}
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => (
        <StyleAutoCompleteMenuItem component="div" {...props}>
          {option.label}
        </StyleAutoCompleteMenuItem>
      )}
      renderInput={(params) => (
        <TextField
          fullWidth={true}
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            startAdornment: <StyledSearchIcon position="start" />,
          }}
        />
      )}
      PaperComponent={CustomPaper}
      ListboxComponent={CustomListbox}
    />
  )
}

const CustomPaper = (props) => {
  return <StyledPaper {...props} />
}

const CustomListbox = (props) => {
  return <StyledListBox {...props}></StyledListBox>
}
export default React.memo(CustomAutoComplete)
