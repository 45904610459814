import React from 'react'
import ErrorBoundaryWidget from '../ErrorBoundary/ErrorBoundaryWidget.jsx'
import { Box } from '@mui/material'

function Widget(props) {
  const { component, widgetHeader } = props

  return (
    <Box>
      {widgetHeader}
      <ErrorBoundaryWidget>{component}</ErrorBoundaryWidget>
    </Box>
  )
}

export default Widget
