import { Button } from '@mui/material'
import { styled } from '@mui/material/styles'

const ContainedButton = styled(Button)(({ theme, width }) => ({
  backgroundColor: theme.palette.blue.blue7,
  width: width ?? '100%',
  height: '40px',
  border: 'none',
  borderRadius: 4,
  color: theme.palette.primary.main,
  boxShadow: 'none',
  textTransform: 'uppercase',
  fontWeight: 600,
  fontSize: theme.typography.fontSizes.m,
  paddingBlock: 4,
  '&:hover': {
    transition: '.2s',
    backgroundColor: theme.palette.primary.buttonHover,
    boxShadow: 'none',
  },
  '&.mediumButton': {
    height: 32,
    fontSize: theme.typography.fontSize,
  },
  '&.smallButton': {
    height: 24,
    fontSize: theme.typography.fontSize,
  },
  '&.redButton': {
    backgroundColor: theme.palette.red.red4,
    '&:hover': {
      backgroundColor: theme.palette.red.red4,
    },
  },
  '&:disabled': {
    color: theme.palette.primary.divider,
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.primary.divider}`,
  },
}))

export default ContainedButton
