import React, { useEffect } from 'react'
import { ModalContent, ModalHeader, ModalHeaderDivider, Title, CostumeInput, Article, ErrorText } from './TwoFactorAuthentication.style'
import { Box, Grid } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { MODALS } from '../../../../utils/constants'
import { setModal } from '../../../../redux/slices/Modal/modalSlice'
import { useNavigate } from 'react-router-dom'
import { login2FA, set2FAError, setQrImage, setSecret } from '../../../../redux/slices/Auth/authSlice'
import useForm from '../../../../hooks/useForm'
import { StyledCancelButton } from '../../../SharedComponents/Buttons/CustomButton'
import { StyledLoaderButton } from '../../../SharedComponents/Buttons/LoaderButton'
import { useTheme } from '@emotion/react'

function TwoFactorAuthentication() {
  const formInitial = { six_digits: '' }
  const theme = useTheme()

  const formRules = (value) => {
    if (String(value).length !== 7) {
      return true
    }
  }

  const { form, error } = useForm(formInitial, formRules)
  const [formState, setFormState] = form
  const [errorState, setError] = error
  const token = useSelector((state) => state.auth.twoFactAuth.token)
  const qrImage = useSelector((state) => state.auth.twoFactAuth.qrImage)
  const secret = useSelector((state) => state.auth.twoFactAuth.secret)
  const twoFALoader = useSelector((state) => state.auth.twoFactAuth.loader)
  const twoFAError = useSelector((state) => state.auth.twoFactAuth.error)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(setQrImage({ qrImage: null }))
      dispatch(setSecret({ secret: null }))
      if (twoFAError) {
        dispatch(set2FAError({ message: '' }))
      }
    }
  }, [])

  const handelCancelButton = () => {
    dispatch(setModal({ type: MODALS.MODAL_2FA, isOpen: false }))
  }

  const handelContinueButton = async () => {
    if (String(formState.six_digits).length != 6 || isNaN(formState.six_digits)) {
      setError({ six_digits: true })
      return
    }
    dispatch(login2FA(formState, token, navigate))
  }

  const handleSubmit = (event) => {
    if (['Enter', 'NumpadEnter'].includes(event.code)) {
      handelContinueButton()
    }
  }

  const handelDisabledContinueButton = () => {
    if (String(formState.six_digits).length !== 6) return true
    else return false
  }

  const downloadKey = () => {
    const element = document.createElement('a')
    const file = new Blob([secret], { type: 'text/plain' })
    element.href = URL.createObjectURL(file)
    element.download = 'myFile.txt'
    document.body.appendChild(element)
    element.click()
  }

  return (
    <ModalContent>
      <ModalHeader>Google Authenticator</ModalHeader>
      <ModalHeaderDivider />
      {qrImage !== null && (
        <>
          <Title>Setup authenticator app</Title>
          <Article>
            Authenticator apps and browser extensions like
            <span style={{ textDecoration: 'underline' }}>1Password, Author, Microsoft Authenticator,</span>
            etc. generate one-time passwords that are used as a second factor to verify your identity when prompted during sign-in.
          </Article>
          <Title>Scan the QR code</Title>
          <Article>
            Use an authenticator app or browser extension to scan.
            <span style={{ textDecoration: 'underline' }}>Learn more about enabling 2FA</span>.
          </Article>
          <img alt="qr image" src={qrImage} style={{ width: '137px', height: '137px' }} />
          <Article>
            Unable to scan? You can use the &nbsp;
            <span onClick={downloadKey} style={{ textDecoration: 'underline', color: theme.palette.blue.blue7, cursor: 'pointer' }}>
              setup key
            </span>
            &nbsp; to manually configure your authenticator app.
          </Article>
        </>
      )}
      <Title>Verify the code from the app</Title>
      <CostumeInput
        type="password"
        placeholder="XXXXXX"
        name="six_digits"
        error={errorState.six_digits === true}
        value={formState.six_digits}
        onChange={setFormState}
        onKeyUp={handleSubmit}
      />
      <ErrorText>{twoFAError}</ErrorText>
      <Box display="flex" justifyContent={'space-between'} style={{ marginTop: '32px' }}>
        <Grid container>
          <Grid item xs={5.5} lg={4}>
            <StyledCancelButton variant="outlined" onClick={handelCancelButton}>
              Cancel
            </StyledCancelButton>
          </Grid>
          <Grid item xs={1} lg={4}></Grid>

          <Grid item xs={5.5} lg={4}>
            <StyledLoaderButton loading={twoFALoader} disabled={handelDisabledContinueButton()} variant="contained" onClick={handelContinueButton}>
              Continue
            </StyledLoaderButton>
          </Grid>
        </Grid>
      </Box>
    </ModalContent>
  )
}

export default TwoFactorAuthentication
