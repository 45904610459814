import React, { useState } from 'react'
import CardContent from '@mui/material/CardContent'
import { Stack, InputAdornment } from '@mui/material'
import { MyCard, Header, Input, CustomOutlinedInput, CustomFormControl, CustomInputLabel, Logo, CustomIconButton } from './Login.style'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import useForm from '../../hooks/useForm'
import { ENDPOINTS, MODALS, REST_GATEWAY_URL } from '../../utils/constants'
import { useDispatch } from 'react-redux'
import { setModal } from '../../redux/slices/Modal/modalSlice'
import axios from 'axios'
import { set2FaToken } from '../../redux/slices/Auth/authSlice'
import { handelNotifications } from '../../redux/slices/Notification/notificationSlice'
import { StyledLoaderButton } from '../SharedComponents/Buttons/LoaderButton'
import { ReactComponent as EXTPLogo } from '../assets/icons/extpCube.svg'

function Login() {
  const formInitial = {
    username: '',
    password: '',
  }
  const [showPassword, setShowPassword] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { form, error } = useForm(formInitial)

  const [errorState, setErrorState] = error
  const [formState, setFormState] = form

  const validateLoginCall = () => {
    let haveError = false
    const error2 = { ...errorState }
    if (formState.username === '') {
      error2.username = true
      haveError = true
    }
    if (formState.password === '' || formState.password.length > 20) {
      error2.password = true
      haveError = true
    }
    setErrorState(error2)
    return haveError
  }

  const dispatch = useDispatch()

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleSubmit = async () => {
    const url = `${REST_GATEWAY_URL}${ENDPOINTS.AUTH}`
    try {
      setIsLoading(true)
      if (!validateLoginCall()) {
        const result = await axios.put(url, formState)
        dispatch(set2FaToken(result.data))
        dispatch(setModal({ type: MODALS.MODAL_2FA, isOpen: true }))
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        dispatch(
          handelNotifications({
            id: Date.now(),
            message: error?.response?.data?.error || 'Please Check Your UserName And Password',
            type: 'error',
          })
        )
      }
    }
  }

  return (
    <MyCard>
      <CardContent>
        <Stack>
          <Logo>
            <EXTPLogo style={{ height: '100%', width: '100%' }} />
          </Logo>
          <Header variant="h5">Welcome!</Header>
          <Input type="text" label="Username" name="username" value={formState.username} onChange={setFormState} error={errorState.username === true} />
          <CustomFormControl variant="outlined">
            <CustomInputLabel htmlFor="outlined-adornment-password" error={errorState.password === true}>
              Password
            </CustomInputLabel>
            <CustomOutlinedInput
              id="outlined-adornment-password"
              name="password"
              value={formState.password}
              onChange={setFormState}
              type={showPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <CustomIconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </CustomIconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </CustomFormControl>
          <StyledLoaderButton
            style={{
              marginLeft: '48px',
              marginRight: '48px',
            }}
            width={'auto'}
            onClick={handleSubmit}
            loading={isLoading}
            variant="outlined"
          >
            Sign In
          </StyledLoaderButton>
        </Stack>
      </CardContent>
    </MyCard>
  )
}

export default Login
