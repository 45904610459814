import { styled } from '@mui/system'
import { Typography, Button, Grid } from '@mui/material'

export const ModalContent = styled(Grid)(({ theme }) => ({
  maxWidth: '540px',
  background: `${theme.palette.black.black2} 0% 0% no-repeat padding-box`,
  padding: '32px 41px',
  display: 'flex',
  flexDirection: 'column',
  margin: '0 32px',
}))

export const ModalHeader = styled(Typography)(({ theme }) => ({
  marginTop: '32px',
  textAlign: 'center',
  color: theme.palette.red.red2,
  fontSize: theme.typography.fontSizes.xxxl,
}))

export const Article = styled(Typography)(({ theme }) => ({
  color: theme.palette.white.white1,
  textAlign: 'center',
  fontSize: theme.typography.fontSizes.xl,
  marginTop: '32px',
}))

export const TitleExpireIn = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  textAlign: 'center',
  fontSize: theme.typography.fontSizes.m,
  marginTop: '32px',
}))

export const ButtonContainer = styled(Grid)({
  marginTop: '32px',
})

export const ButtonStayConnected = styled(Button)(({ theme }) => ({
  width: '100%',
  height: '40px',
  textAlign: 'center',
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.black.black2,
  fontSize: theme.typography.fontSizes.lg,
  fontWeight: 600,
  boxShadow: `0 0 0 1px ${theme.palette.text.primary}`,
  '&:hover': {
    color: theme.palette.blue.blue7,
    boxShadow: `0 0 0 1px ${theme.palette.blue.blue7}`,
  },
}))

export const ButtonSignOut = styled(Button)(({ theme }) => ({
  width: '100%',
  height: '40px',
  textAlign: 'center',
  fontWeight: '600',
  backgroundColor: theme.palette.red.red2,
  color: theme.palette.black.black3,
  fontSize: theme.typography.fontSizes.lg,
  '&:hover': {
    backgroundColor: theme.palette.red.hover,
    color: theme.palette.black.black3,
  },
}))

export const Timer = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.fontSizes.xxxb,
  textAlign: 'center',
  color: 'white',
  marginTop: '9px',
}))
