import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  type: '',
  isOpen: false,
}

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setModal: (state, action) => {
      state.type = action.payload.type
      state.isOpen = action.payload.isOpen
    },
  },
})

export const { setModal } = modalSlice.actions

export default modalSlice.reducer
