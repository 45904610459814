import React from 'react'
import { GridWithMargin } from './SideButtons.style'
import clsx from 'clsx'
import { StyledCustomButton } from '../../SharedComponents/Buttons/CustomButton'

const SideButtons = ({ handleSide, side, isRequesting }) => {
  const buyButtonClasses = clsx({
    buyButton: side === 'BUY' && !isRequesting,
    isRequestBuyButton: side === 'BUY' && isRequesting,
    naturalButton: side !== 'BUY',
  })

  const sellButtonClasses = clsx({
    sellButton: side === 'SELL' && !isRequesting,
    isRequestSellButton: side === 'SELL' && isRequesting,
    naturalButton: side !== 'SELL',
  })

  return (
    <GridWithMargin item xs={12}>
      <div style={{ textAlign: 'center' }}>
        <StyledCustomButton width={'50%'} onClick={() => handleSide('SELL')} className={sellButtonClasses} disabled={isRequesting}>
          SELL
        </StyledCustomButton>
        <StyledCustomButton width={'50%'} onClick={() => handleSide('BUY')} className={buyButtonClasses} disabled={isRequesting}>
          BUY
        </StyledCustomButton>
      </div>
    </GridWithMargin>
  )
}

export default SideButtons
