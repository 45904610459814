import { styled } from '@mui/system'
import { Typography as TypographyBase } from '@mui/material'

export const TextColor = styled(TypographyBase)(({ theme }) => ({
  color: theme.palette.orange.orange3,
  fontWeight: '550',
  fontSize: theme.typography.fontSize,
}))

export const Typography = styled(TypographyBase)(({ theme }) => ({
  fontSize: theme.typography.fontSize,
}))
