import React from 'react'
import { Grid, TableHead } from '@mui/material'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import { TableContainer, StyledTable, ClickTradingTableLight, StyledTablePriceCell, StyledTableQtyGrid, CustomTableRowHeader, CustomTableCellHeader } from './STTable.style'
import ShortText from '../SharedComponents/ShortText/ShortText'
import { numberWithCommas } from '../../utils/helperFunctions'

function STTable(props) {
  const { dataFromWS } = props
  const sortAscKeys = (objectToSort) => {
    const keysSorted = Object.keys(objectToSort).sort((a, b) => Number(a) - Number(b))
    return keysSorted.map((key) => ({
      key: Number(key),
      value: objectToSort[key],
    }))
  }

  return (
    <TableContainer container justifyContent="center">
      <Grid item xs={12}>
        {dataFromWS?.level && (
          <StyledTable aria-label="levels-table">
            <TableHead>
              <CustomTableRowHeader>
                {['BID', 'Qty', 'ASK'].map((c, i) => (
                  <CustomTableCellHeader key={i} align="center">
                    {c}
                  </CustomTableCellHeader>
                ))}
              </CustomTableRowHeader>
            </TableHead>
            <TableBody>
              {sortAscKeys(dataFromWS.level).map((item, index) => (
                <TableRow key={index}>
                  <StyledTablePriceCell align="center" className={'RedCell'}>
                    <ShortText text={numberWithCommas(item?.value?.bid?.price)} char={'*'} />
                  </StyledTablePriceCell>

                  <ClickTradingTableLight align="center">
                    <StyledTableQtyGrid item className={'LevelLabel NumbersFont'}>
                      {item?.key}
                    </StyledTableQtyGrid>
                  </ClickTradingTableLight>

                  <StyledTablePriceCell align="center" className={'GreenCell'}>
                    <ShortText text={numberWithCommas(item?.value?.ask?.price)} char={'*'} />
                  </StyledTablePriceCell>
                </TableRow>
              ))}
            </TableBody>
          </StyledTable>
        )}
      </Grid>
    </TableContainer>
  )
}

export default STTable
