import { styled } from '@mui/system'
import { Button, Grid } from '@mui/material'

export const GridWithMargin = styled(Grid)({
  margin: '20px 20px 0',
})

export const CustomBottom = styled(Button)(({ theme }) => ({
  color: 'black',
  fontSize: theme.typography.fontSizes.lg,
  fontWeight: 600,
  '&.buyButton': {
    width: '50%',
    height: '40px',
    backgroundColor: `${theme.palette.green.green1} !important`,
  },

  '&.sellButton': {
    width: '50%',
    height: '40px',
    backgroundColor: `${theme.palette.red.red1} !important`,
  },
  '&.isRequestBuyButton': {
    width: '50%',
    height: '40px',
    backgroundColor: `${theme.palette.black.black3} !important`,
    border: `2px solid ${theme.palette.green.green1}`,
    color: `${theme.palette.green.green1} !important`,
  },
  '&.isRequestSellButton': {
    width: '50%',
    height: '40px',
    backgroundColor: `${theme.palette.black.black3} !important`,
    border: `2px solid ${theme.palette.red.red1}`,
    color: `${theme.palette.red.red1} !important`,
  },

  '&.naturalButton': {
    width: '50%',
    height: '40px',
    backgroundColor: `${theme.palette.black.black2} !important`,
    color: `${theme.palette.grey.grey1} !important`,
  },
}))
