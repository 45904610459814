import workerInstances from '../services'

// export function numberWithCommas(num) {
//   if (typeof num === 'undefined' || num === null || num === '') {
//     return
//   }
//   if (isNaN(num)) {
//     return num
//   }
//   let str = num?.toString().split('.')
//   str[0] = !parseInt(str[0])
//     ? 0
//     : parseInt(str[0])
//         .toString()
//         .replace(/,/g, '') // remove prev commas
//         .replace(/\B(?=(\d{3})+(?!\d))/g, ',') // apply new commas
//   return str.join('.')
// }

export const deviated_limit_price = (limit_price, slippage_bps, side) => {
  if (side === 'buy') {
    return limit_price * (1 + slippage_bps / 10000)
  } else if (side === 'sell') {
    return limit_price * (1 - slippage_bps / 10000)
  } else {
    return limit_price
  }
}

export function numberWithCommas(number, separator = '.') {
  if (typeof number === 'undefined' || number === null || number === '') {
    return number
  }
  // Convert the number to a string with the default separator
  let parts = number.toString().split('.')
  let formattedNumber = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') + (parts[1] ? separator + parts[1] : '')
  return formattedNumber
}

export const capitalizeAllFirstLetters = (string) => {
  if (!string) return
  const words = string.split('_')
  return words.length ? words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : string
}

export function formatNumber(number, type, decimal) {
  if (!isNaN(Number(number))) {
    switch (type) {
      case 'price':
      case 'quantity':
        number = Number(number).toLocaleString('en-GB', {
          maximumFractionDigits: decimal,
        })
        break
      case 'nominal':
        number = Number(number).toLocaleString('en-GB', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
        break
      case 'data_table':
        number = Number(number).toLocaleString('en-GB', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        })
        break
      case 'notWidget':
        if (decimal)
          number = Number(number).toLocaleString('en-GB', {
            maximumFractionDigits: decimal,
          })
        break
      case 'has_decimals':
        number = Number(number).toLocaleString('en-GB', {
          minimumFractionDigits: decimal,
          maximumFractionDigits: decimal,
        })
        break
      case 'settlement_modal':
        number = Number(number).toLocaleString('en-GB', {
          maximumFractionDigits: 8,
        })
        break
      case 'fullwide':
        number = Number(number).toLocaleString('en-GB', {
          maximumFractionDigits: 16,
        })
        break
      default:
        break
    }
  }
  return number
}

export const validateQty = (value, decimals) => {
  if (!isNaN(decimals)) {
    let regex
    if (decimals === 0) {
      regex = /^\d+$/
    } else if (decimals > 0) {
      regex = new RegExp(`^\\d*(\\.)?(\\d{0,${decimals}})?$`)
    }
    return regex.test(value)
  }
}

export const validMaxDigits = (splittedValue, cryptoCoin) => {
  let wholeLen = splittedValue?.[0]?.length
  if (cryptoCoin) {
    return wholeLen < 18
  } else {
    return wholeLen < 14 || !wholeLen
  }
}

export const validateSlippage = (value) => {
  let regex = /^\d*$/
  return regex.test(value) && value >= 0 && value <= 20
}

export const closeWidget = (widget, widgetKey) => {
  workerInstances.WebSocketPricesInstance.sendEvent({
    type: 'close-widget',
    data: {
      widget_name: widget,
      widget_id: widgetKey,
    },
  })
}

export const getBaseCurrency = (product) => {
  if (product.length === 0) return ''
  return product.split('-')[0]
}

export const getQuoteCurrency = (product) => {
  if (product.length === 0) return ''
  return product.split('-')[1]
}
