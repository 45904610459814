import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { ReactComponent as CheckmarkIcon } from '../../assets/icons/checkmark.svg'
import { CheckMarkButton, Checkmark, CheckmarkContainer, Vcontainer, Vtext } from './AnimatedSuccess.style'

const AnimatedSuccess = () => {
  const [showCircle, setShowCircle] = useState(false)

  useEffect(() => {
    setShowCircle(true)
  }, [])

  const CheckMarkButtonClass = clsx('Vspin Vcircle Vbutton', {
    focusVspin: showCircle,
  })

  return (
    <Vcontainer tabIndex={0}>
      <CheckMarkButton className={CheckMarkButtonClass}>
        <CheckmarkContainer>
          <Checkmark>
            <CheckmarkIcon />
          </Checkmark>
        </CheckmarkContainer>
      </CheckMarkButton>
      <Vtext>Succeeded</Vtext>
    </Vcontainer>
  )
}

export default AnimatedSuccess
