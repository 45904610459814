import { styled } from '@mui/system'
import { Typography } from '@mui/material'

export const GreyTimeTypo = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.fontSize,
  color: theme.palette.grey.grey14,
}))

export const BlackTimeTypo = styled(Typography)(({ theme }) => ({
  color: theme.palette.white.white1,
  fontSize: theme.typography.fontSize,
  textAlign: 'right',
}))

export const VersionControl = styled('span')(({ theme }) => ({
  paddingLeft: 6,
  color: theme.palette.grey.grey14,
}))
