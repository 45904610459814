import { styled } from '@mui/system'
import Typography from '@mui/material/Typography'
import { Button } from '@mui/material'

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  justifyContent: 'flex-start',
}))

export const StyledFilterText = styled(Typography)(({ theme }) => ({
  color: theme.palette.white.white1,
  fontSize: theme.typography.fontSizes.xl,
}))

export const StyledResetText = styled(Typography)(({ theme }) => ({
  color: theme.palette.orange.orange3,
  fontSize: theme.typography.fontSizes.xl,
  textDecoration: 'underline',
  cursor: 'pointer',
}))

export const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey.grey1,
  marginTop: '8px',
  marginBottom: '8px',
}))

export const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.orange.orange3,
  fontSize: theme.typography.fontSizes.xl
}))