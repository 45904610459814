import { Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ExposureContainer } from './Exposure.styles'
import { numberWithCommas } from '../../utils/helperFunctions'

function Exposure() {
  const exposures = useSelector((state) => state?.exposures?.exposures)
  const [usdExposure, setUsdExposure] = useState(null)

  useEffect(() => {
    if (exposures) {
      const usdExposureDetails = exposures.find((exposure) => exposure.asset === 'USD')
      setUsdExposure(usdExposureDetails && usdExposureDetails.max_exposure)
    }
  }, [exposures])

  return (
    <ExposureContainer container>
      <Typography color="secondary">Exposure</Typography>
      <Grid item container justifyContent="center" style={{ width: 'calc(100% - 80px' }}>
        {usdExposure && <Typography>{`$${numberWithCommas(usdExposure)}`}</Typography>}
      </Grid>
    </ExposureContainer>
  )
}

export default Exposure
