import React, { memo, useState } from 'react'
import { ENDPOINTS, WIDGETS, WIDGETS_ID } from '../../utils/constants'
import CustomTable from '../Table/SimpleTable'
import { Grid } from '@mui/material'

import useAxios from '../../hooks/useAxios'
import { StyledTableCell } from '../Table/SimpleTable.style'
import { useTheme } from '@emotion/react'
import LoadingOverlay from '../NotificationOverlays/LoadingOverlay'
import ShortText from '../SharedComponents/ShortText/ShortText'
import { numberWithCommas } from '../../utils/helperFunctions'

function Balance() {
  const columns = ['Asset', 'Quantity']
  const theme = useTheme()
  const [loading, setLoading] = useState(false)
  const [response] = useAxios({
    setLoading,
    endPoint: ENDPOINTS.BALANCES,
    method: 'GET',
    widget: WIDGETS.SPOT_BALANCES,
  })

  const balanceData = () => {
    if (response.status === 200) {
      return response.data.map(({ asset, amount }) => ({
        asset: (
          <StyledTableCell component="td" scope="cell" align="center">
            {asset}
          </StyledTableCell>
        ),
        amount:
          +amount > 0 ? (
            <StyledTableCell component="td" scope="cell" align="center" color={theme.palette.green.green1}>
              <ShortText text={numberWithCommas(amount)} char={'*'} color={theme.palette.green.green1} />
            </StyledTableCell>
          ) : amount === 0 ? (
            <StyledTableCell component="td" scope="cell" align="center" color={theme.palette.grey.grey1}>
              <ShortText text={amount} char={'*'} />
            </StyledTableCell>
          ) : (
            <StyledTableCell component="td" scope="cell" align="center" color={theme.palette.red.red1}>
              <ShortText text={numberWithCommas(amount)} char={'*'} color={theme.palette.red.red1} />
            </StyledTableCell>
          ),
      }))
    } else {
      return []
    }
  }

  return (
    <Grid>
      {loading === false ? (
        <Grid item xs={12}>
          <CustomTable rows={balanceData()} columns={columns} id={WIDGETS_ID.SPOT_BALANCES} isopendrawerfilter={'false'} />
        </Grid>
      ) : (
        <LoadingOverlay height={'calc(100% - 34px)'} />
      )}
    </Grid>
  )
}

export default memo(Balance)
