import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { set2FAError } from '../redux/slices/Auth/authSlice'
function useForm(form, formRules) {
  const [state, setState] = useState(form)
  const [error, setError] = useState(form)
  const twoFAError = useSelector((state) => state.auth.twoFactAuth.error)
  const dispatch = useDispatch()
  const setForm = (event) => {
    const key = event.target.name
    const value = event.target.value
    if (typeof formRules === 'function') {
      if (formRules(value)) {
        writeToInput()
      }
    } else {
      writeToInput()
    }

    function writeToInput() {
      {
        if (error[key]) {
          setError({ ...error, [key]: false })
        }
        if (twoFAError) {
          dispatch(set2FAError({ message: '' }))
        }
        setState({ ...state, [key]: value })
      }
    }
  }

  return { form: [state, setForm], error: [error, setError] }
}

export default useForm
