import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
// import workerInstances from "../../services";
// import * as actionSnackBar from "../SnackBar/snackBarSlice";
// import { setLogout } from "../Auth/authSlice";
import { REST_GATEWAY_URL, ENDPOINTS, WIDGETS } from '../../../utils/constants'
import { handelNotifications } from '../Notification/notificationSlice'

export const utilsSlice = createSlice({
  name: 'utils',
  initialState: {
    products: [],
    ws: null,
    noInternet: false,
    resetConnection: true,
    deleteClientConfirmation: false,
    reloadDataByWidget: {
      'SPOT BALANCES': false,
      'TRADES BLOTTER': false,
    },
    tokenExpired: false,
  },
  reducers: {
    getUtils: (state, action) => {
      state.utils = action.payload
    },
    setWs: (state, action) => {
      state.ws = action.payload
    },
    setProducts: (state, action) => {
      state.products = action.payload
    },
    updateReset: (state, action) => {
      state.resetConnection = action.payload
    },
    openDeleteConfirmationModal: (state, action) => {
      state.deleteClientConfirmation = action.payload
    },
    setReloadData: (state, action) => {
      state.reloadDataByWidget[action.payload.widget] = action.payload.refresh
    },
    setTokenExpired: (state, action) => {
      state.tokenExpired = action.payload
    },
  },
})

export const getProducts = () => async (dispatch) => {
  try {
    const res = await axios.get(`${REST_GATEWAY_URL}${ENDPOINTS.INSTRUMENTS}`)
    if (res.status === 200) {
      const btcUsdIndex = res.data.findIndex((product) => product.symbol === 'BTC-USD')
      if (btcUsdIndex !== -1) {
        const btcUsdProduct = res.data.splice(btcUsdIndex, 1)[0]
        res.data.unshift(btcUsdProduct)
      }
      dispatch(setProducts(res.data))
    }
  } catch (error) {
    dispatch(
      handelNotifications({
        id: Date.now(),
        message: `Could not get products`,
        type: 'error',
      })
    )
  }
}

export const refreshSpotTraderAndSpotBalance = (blotter) => async (dispatch) => {
  dispatch(setReloadData({ widget: blotter, refresh: true }))
  dispatch(setReloadData({ widget: WIDGETS.SPOT_BALANCES, refresh: true }))
  setTimeout(() => {
    dispatch(setReloadData({ widget: blotter, refresh: false }))
    dispatch(setReloadData({ widget: WIDGETS.SPOT_BALANCES, refresh: false }))
  }, 5000)
}

export const { getUtils, setWs, setProducts, updateReset, openDeleteConfirmationModal, setReloadData, setTokenExpired } = utilsSlice.actions

export default utilsSlice.reducer
