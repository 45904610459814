import React from 'react'
import { Widget, WidgetHeaderStyled, WidgetHeaderDivider, WidgetContainer, CustomReplayButton, IconButton } from './WidgetHeader.style.js'

function WidgetHeader(props) {
  const { title, has_reload, refreshData } = props
  return (
    <Widget>
      <WidgetContainer>
        <WidgetHeaderStyled>{title}</WidgetHeaderStyled>
        {has_reload && (
          <IconButton onClick={refreshData}>
            <CustomReplayButton></CustomReplayButton>
          </IconButton>
        )}
      </WidgetContainer>
      <WidgetHeaderDivider />
    </Widget>
  )
}

export default WidgetHeader
