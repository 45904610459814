import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Typography, Box, CircularProgress } from '@mui/material'
import { useTheme } from '@emotion/react'

function CircularProgressWithLabel(props) {
  const theme = useTheme()
  return (
    <Box position="relative" display="inline-flex" marginRight="8px">
      <CircularProgress style={{ color: theme.palette.primary.main }} variant="determinate" {...props} />
      <Box top={4} left={4} bottom={0} right={0} position="absolute" display="flex" alignItems="center" justifyContent="center" width={16} height={16}>
        <Typography
          variant="subtitle1"
          component="div"
          style={{
            textAlign: 'center',
            fontWeight: 600,
          }}
        >
          {`${Math.round(props.label)}`}
        </Typography>
      </Box>
    </Box>
  )
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
}

export default function CircularStatic() {
  const [progress, setProgress] = React.useState(1)
  const [label, setLabel] = React.useState(7)

  useEffect(() => {
    setProgress(1)
    setLabel(7)
  }, [])

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 100 / 7))
      setLabel((label) => (label <= 0 ? 7 : label - 1))
    }, 1000)
    return () => {
      clearInterval(timer)
    }
  }, [])

  return <CircularProgressWithLabel value={progress} label={label} size={24} />
}
