import PropTypes from 'prop-types'
import NavBar from '../NavBar/NavBar'
import React, { useEffect } from 'react'
import workerInstances from '../../services'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import LoadingOverlay from '../../components/NotificationOverlays/LoadingOverlay'
import { setAuthToken } from '../../utils/systemHandler'
import * as exposureActions from '../../redux/slices/Exposures/exposuresSlice'
import { getProducts } from '../../redux/slices/Utils/utilsSlice'

const Layout = ({ children }) => {
  const location = useLocation()
  const token = useSelector((state) => state.auth.token)

  useEffect(() => {
    setAuthToken(token)
    dispatch(getProducts())
  }, [token])

  useEffect(() => {
    if (workerInstances && workerInstances?.WebSocketPricesInstance && token) {
      workerInstances.WebSocketPricesInstance.connectWS(token)
    }
  }, [token, location, workerInstances?.WebSocketPricesInstance])

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(exposureActions.getAllExposures())
  }, [])

  return (
    <Grid>
      {!token && !workerInstances?.WebSocketPricesInstance ? (
        <LoadingOverlay height={'calc(100% - 34px)'} />
      ) : (
        <>
          <NavBar />
          <div>{children}</div>
        </>
      )}
    </Grid>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
