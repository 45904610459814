import React from 'react'
import CustomTextField from '../TextFields/CustomTextField'
import './CustomInput.style.css'

const CustomInput = ({ value, onChange, name, placeholder, disabled, InputProps, error, inputType, label }) => {
  return (
    <CustomTextField
      label={label}
      type={inputType}
      disabled={disabled}
      value={value}
      autoComplete="off"
      name={name}
      placeholder={placeholder}
      onChange={(e) => onChange(e)}
      variant="outlined"
      id={name}
      InputProps={InputProps}
      error={error}
      className="disableArrows shrink"
    />
  )
}

export default CustomInput
