import React from 'react'
import AppRouter from './components/AppRouter/AppRouter'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import colorTheme from './utils/theme'
import typography from './utils/typography'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import Notifications from './components/Notifications/Notifications'

function App() {
  const theme = createTheme({
    palette: colorTheme,
    typography,
  })

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <AppRouter />
        <Notifications />
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default App
