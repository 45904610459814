import { createSlice } from '@reduxjs/toolkit'

// Slice
export const timeSlice = createSlice({
  name: 'time',
  initialState: {
    timeFromServer: '',
  },

  reducers: {
    setTime: (state, action) => {
      state.timeFromServer = action.payload
    },
  },
})

export const { setTime } = timeSlice.actions

export default timeSlice.reducer
