const layout = {
  lg: [
    {
      w: 3,
      h: 3.15,
      x: 0,
      y: 0.5,
      minH: 0.5,
      i: '1', // SPOT_TRADER
      moved: false,
      static: true,
    },
    {
      w: 3,
      h: 2.6,
      x: 3,
      y: 0.5,
      minH: 0.5,
      i: '2', // SPOT_BALANCES
      moved: false,
      static: true,
    },
    {
      w: 3,
      h: 1.65,
      x: 0,
      y: 3.65,
      minH: 0.5,
      i: '4', // RFQ
      moved: false,
      static: true,
    },
    {
      w: 9,
      h: 2.2,
      x: 3,
      y: 3.1,
      minH: 0.5,
      i: '5', // TRADES_BLOTTER
      moved: false,
      static: true,
    },
    {
      w: 6,
      h: 2.6,
      x: 6,
      y: 0.5,
      minH: 0.5,
      i: '6', // TRADING_VIEW
      moved: false,
      static: true,
    },
    {
      w: 12,
      h: 0.5,
      x: 0,
      y: 0,
      minH: 0.5,
      i: '7', // TICKER_TAPE
      moved: false,
      static: true,
    },
  ],
  md: [
    {
      w: 3.5,
      h: 3.15,
      x: 0,
      y: 0.5,
      minH: 0.5,
      i: '1', // SPOT_TRADER
      moved: false,
      static: true,
    },
    {
      w: 3,
      h: 2.6,
      x: 3.5,
      y: 0.5,
      minH: 0.5,
      i: '2', // SPOT_BALANCES
      moved: false,
      static: true,
    },
    {
      w: 3.5,
      h: 2,
      x: 0,
      y: 3.65,
      minH: 0.5,
      i: '4', // RFQ
      moved: false,
      static: true,
    },
    {
      w: 6.5,
      h: 2.55,
      x: 3.5,
      y: 3.1,
      minH: 0.5,
      i: '5', // TRADES_BLOTTER
      moved: false,
      static: true,
    },
    {
      w: 3.5,
      h: 2.6,
      x: 7,
      y: 0.5,
      minH: 0.5,
      i: '6', // TRADING_VIEW
      moved: false,
      static: true,
    },
    {
      w: 12,
      h: 0.5,
      x: 0,
      y: 0,
      minH: 0.5,
      i: '7', // TICKER_TAPE
      moved: false,
      static: true,
    },
  ],
  sm: [
    {
      w: 3,
      h: 3.15,
      x: 0,
      y: 0.5,
      minH: 0.5,
      i: '1', // SPOT_TRADER
      moved: false,
      static: true,
    },
    {
      w: 3,
      h: 2.55,
      x: 3,
      y: 3.1,
      minH: 0.5,
      i: '2', // SPOT_BALANCES
      moved: false,
      static: true,
    },
    {
      w: 3,
      h: 2,
      x: 0,
      y: 3.65,
      minH: 0.5,
      i: '4', // RFQ
      moved: false,
      static: true,
    },
    {
      w: 6,
      h: 3,
      x: 0,
      y: 5.65,
      minH: 0.5,
      i: '5', // TRADES_BLOTTER
      moved: false,
      static: true,
    },
    {
      w: 3,
      h: 2.6,
      x: 3,
      y: 0.5,
      minH: 0.5,
      i: '6', // TRADING_VIEW
      moved: false,
      static: true,
    },
    {
      w: 12,
      h: 0.5,
      x: 0,
      y: 0,
      minH: 0.5,
      i: '7', // TICKER_TAPE
      moved: false,
      static: true,
    },
  ],
  xs: [
    {
      w: 4,
      h: 3.15,
      x: 0,
      y: 0.5,
      minH: 0.5,
      i: '1', // SPOT_TRADER
      moved: false,
      static: true,
    },
    {
      w: 4,
      h: 2,
      x: 0,
      y: 8.25,
      minH: 0.5,
      i: '2', // SPOT_BALANCES
      moved: false,
      static: true,
    },
    {
      w: 4,
      h: 2,
      x: 0,
      y: 3.65,
      minH: 0.5,
      i: '4', // RFQ
      moved: false,
      static: true,
    },
    {
      w: 4,
      h: 3,
      x: 0,
      y: 10.25,
      minH: 0.5,
      i: '5', // TRADES_BLOTTER
      moved: false,
      static: true,
    },
    {
      w: 4,
      h: 2.6,
      x: 0,
      y: 5.65,
      minH: 0.5,
      i: '6', // TRADING_VIEW
      moved: false,
      static: true,
    },
    {
      w: 12,
      h: 0.5,
      x: 0,
      y: 0,
      minH: 0.5,
      i: '7', // TICKER_TAPE
      moved: false,
      static: true,
    },
  ],
  xxs: [
    {
      w: 2,
      h: 3.1,
      x: 0,
      y: 0.5,
      minH: 0.5,
      i: '1', // SPOT_TRADER
      moved: false,
      static: true,
    },
    {
      w: 2,
      h: 2,
      x: 0,
      y: 8.2,
      minH: 0.5,
      i: '2', // SPOT_BALANCES
      moved: false,
      static: true,
    },
    {
      w: 2,
      h: 2,
      x: 0,
      y: 3.6,
      minH: 0.5,
      i: '4', // RFQ
      moved: false,
      static: true,
    },
    {
      w: 2,
      h: 5,
      x: 0,
      y: 10.2,
      minH: 0.5,
      i: '5', // TRADES_BLOTTER
      moved: false,
      static: true,
    },
    {
      w: 2,
      h: 2.6,
      x: 0,
      y: 5.6,
      minH: 0.5,
      i: '6', // TRADING_VIEW
      moved: false,
      static: true,
    },
    {
      w: 2,
      h: 0.5,
      x: 0,
      y: 0,
      minH: 0.5,
      i: '7', // TICKER_TAPE
      moved: false,
      static: true,
    },
  ],
}
export default layout
