import React from 'react'
import { Grid, Typography, Box } from '@mui/material'
import clsx from 'clsx'
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers'
import ContainedButton from '../SharedComponents/Buttons/ContainedButton'
import { TopSpace, ForwardStart, StyledDateTimePicker } from './AdvancedOrder.style'
import { StyledDisabledCheckbox } from '../SharedComponents/Checkboxes/StyledCheckbox.style'
import CustomInput from '../SharedComponents/CustomInput/CustomInput'

function AdvancedOrder({ advancedForm, updateAdvancedForm, disabledExecution, handleExecuteLimit, handleExecuteMKT, orderType }) {
  const forwardStartClasses = clsx({
    ForwardStartActive: advancedForm.forward_start,
  })

  const handleInputChange = (e) => {
    updateAdvancedForm(e.target.name, e.target.value)
  }

  const handleCheckboxChange = (e) => {
    updateAdvancedForm(e.target.name, e.target.checked)
  }

  return (
    <Grid item xs={12} container>
      <TopSpace container>
        <Grid item xs={6} style={{ paddingRight: 8 }}>
          <CustomInput
            value={advancedForm?.stop_price}
            inputType={'number'}
            onChange={(e) => handleInputChange(e)}
            name={'stop_price'}
            placeholder={'Stop Price (@):'}
            disabled={true}
            InputProps={{
              style: { height: '100%', padding: 0 },
            }}
          />
        </Grid>
        <Grid item xs={6} style={{ paddingLeft: 8 }}>
          <CustomInput
            value={advancedForm?.limit_price}
            inputType={'number'}
            name={'limit_price'}
            onChange={(e) => handleInputChange(e)}
            placeholder={'Limit Price (@):'}
            disabled={orderType !== 'limit'}
          />
        </Grid>
      </TopSpace>
      <ForwardStart container className={forwardStartClasses} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px' }}>
        <Grid item xs={5}>
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <StyledDisabledCheckbox name="forward_start" disabled={true} checked={true} onChange={handleCheckboxChange} />
            <Typography color="secondary" style={{ marginLeft: 3 }}>
              Forward Start
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={7}>
          <StyledDateTimePicker
            disabled={true}
            maxDateMessage={`Date exceeded max date`}
            autoOk
            ampm={false}
            width={'200px'}
            variant="inline"
            format="DD/mm/YYYY hh:mm"
            value={advancedForm?.date}
            margin="normal"
            placeholder="DD/mm/yyyy hh:mm (UTC 0)"
            // onChange={(e) => console.log(e.target)}
            keyboardIcon={<Typography color="secondary">UTC</Typography>}
            viewRenderers={{
              hours: renderTimeViewClock,
              minutes: renderTimeViewClock,
              seconds: renderTimeViewClock,
            }}
          />
        </Grid>
      </ForwardStart>
      <TopSpace item container xs={12} justifyContent="space-between">
        <ContainedButton disabled={disabledExecution()} width="100%" onClick={() => (orderType === 'limit' ? handleExecuteLimit() : handleExecuteMKT())}>
          SUBMIT TRADE
        </ContainedButton>
      </TopSpace>
    </Grid>
  )
}

export default React.memo(AdvancedOrder)
