import { styled } from '@mui/system'
import { Grid } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'

export const TopSpace = styled(Grid)(() => ({
  marginTop: 14,
}))

export const ForwardStart = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.primary.background,
  borderRadius: 4,
  marginTop: '10px',
  '&.ForwardStartActive': {
    backgroundColor: theme.palette.purple.purple3,
  },
}))

export const StyledDateTimePicker = styled(DateTimePicker)(({ theme }) => ({
  backgroundColor: `${theme.palette.primary.main} !important`,
  color: `${theme.palette.grey.grey1} !important`,
  height: '40px',
  '& .MuiInputBase-root': {
    height: '40px',
    padding: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '& .MuiInputBase-input::placeholder': {
    color: `${theme.palette.grey.grey1} !important`,
    height: '40px',
    width: '100%',
    textAlign: 'center',
    '&:placeholder-shown': {
      color: `${theme.palette.grey.grey1} !important`,
    },
  },
  '& .MuiInputBase-input': {
    height: '40px',
  },
  '& .MuiIconButton-root': {
    color: `${theme.palette.grey.grey1} !important`,
  },
}))

// "& .Mui-disabled": {
//   height: "100%", // Set the height to 100% for the disabled state
//   color: `${theme.palette.grey.grey1} !important`,
//   "& .Mui-disabled::placeholder": {
//     color: "green", // Adjust placeholder color for disabled state
//   },
// }
