import React, { useEffect, useState } from 'react'
import { Responsive, WidthProvider } from 'react-grid-layout'
import Widget from '../../components/Widget/Widget'
import { MODALS, WIDGETS, WIDGETS_ID } from '../../utils/constants'
import { WidgetStyled } from './Dashboard.style'
import TradingView from '../../components/TradingView/TradingView'
import TickerTape from '../../components/TickerTape/TickerTape'
import Balance from '../../components/Balance/Balance'
import TradesBlotter from '../../components/TradeBlotter/TradeBlotter'
import RFQ from '../../components/RFQ/RFQ'
import SpotTrader from '../../components/SpotTrader/SpotTrader'
import layout from '../../utils/layout'
import { useDispatch } from 'react-redux'
import { setReloadData } from '../../redux/slices/Utils/utilsSlice'
import ModalApp from '../../components/ModalApp/ModalApp'
import { setModal } from '../../redux/slices/Modal/modalSlice'
import { useSelector } from 'react-redux'
import workerInstances from '../../services'
import BalanceMovement from '../../components/BalanceMovement/BalanceMovement'
import WidgetHeader from '../../components/WidgetHeader/WidgetHeader'
import WidgetHeaderMulti from '../../components/widgetHeaderMulti/widgetHeaderMulti'

const ResponsiveGridLayout = WidthProvider(Responsive)

const DashboardPage = () => {
  const modal = useSelector((state) => state.modal)
  const dispatch = useDispatch()
  const [blotter, setBlotter] = useState(WIDGETS.TRADES_BLOTTER)
  const [isOpenDrawerFilter, setIsOpenDrawerFilter] = useState(false)
  const [isOpenStatement, setIsOpenStatement] = useState(false)

  const refreshServerCall = (widget) => {
    dispatch(setReloadData({ widget, refresh: true }))
    setTimeout(() => {
      dispatch(setReloadData({ widget, refresh: false }))
    }, 5000)
  }

  const workerInstance = workerInstances.WebSocketPricesInstance

  useEffect(() => {
    if (!workerInstance) return

    const receiveSessionCountdownData = (message) => {
      if (message.data.type === 'session_countdown' && modal.type !== MODALS.MODAL_SESSION_TIME_OUT_TIMER && modal.isOpen === false) {
        dispatch(setModal({ type: MODALS.MODAL_SESSION_TIME_OUT_TIMER, isOpen: true }))
      }
    }
    workerInstance.addEventListener('message', receiveSessionCountdownData)

    return () => workerInstance.removeEventListener('message', receiveSessionCountdownData)
  }, [workerInstance])

  const widgets = [
    {
      key: '1',
      id: WIDGETS_ID.SPOT_TRADER,
      component: <SpotTrader blotter={blotter} id={WIDGETS_ID.SPOT_TRADER} />,
    },
    {
      key: '2',
      id: WIDGETS_ID.SPOT_BALANCES,
      widgetHeader: <WidgetHeader title={WIDGETS.SPOT_BALANCES} has_reload={true} refreshData={() => refreshServerCall(WIDGETS.SPOT_BALANCES)} />,
      component: <Balance />,
    },
    {
      key: '4',
      id: WIDGETS_ID.RFQ,
      component: <RFQ blotter={blotter} />,
    },
    {
      key: '5',
      id: WIDGETS_ID.TRADES_BLOTTER,
      widgetHeader: (
        <WidgetHeaderMulti
          titles={[WIDGETS.TRADES_BLOTTER, WIDGETS.BALANCE_MOVEMENT]}
          setBlotter={setBlotter}
          has_reload={true}
          refreshData={() => refreshServerCall(blotter)}
          setIsOpenDrawerFilter={setIsOpenDrawerFilter}
          isOpenDrawerFilter={isOpenDrawerFilter}
          setIsOpenStatement={setIsOpenStatement}
          isOpenStatement={isOpenStatement}
        />
      ),
      component: <>{blotter === WIDGETS.TRADES_BLOTTER ? <TradesBlotter isOpenDrawerFilter={isOpenDrawerFilter} isOpenStatement={isOpenStatement} /> : <BalanceMovement />}</>,
    },
    {
      key: '6',
      id: WIDGETS_ID.TRADING_VIEW,
      component: <TradingView id={WIDGETS_ID.TRADING_VIEW} />,
    },
    {
      key: '7',
      id: WIDGETS_ID.TICKER_TAPE,
      component: <TickerTape id={WIDGETS_ID.TICKER_TAPE} />,
    },
  ]

  return (
    <>
      <ResponsiveGridLayout
        className="layout"
        style={{ height: 'max-content' }}
        layouts={layout}
        breakpoints={{ lg: 1450, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
      // onLayoutChange={(e, layout) => { console.log(layout) }}
      >
        {widgets.map((widget) => {
          const { key, component, widgetHeader, id } = widget
          return (
            <WidgetStyled key={key} id={id}>
              <Widget component={component} widgetHeader={widgetHeader} id={id} />
            </WidgetStyled>
          )
        })}
      </ResponsiveGridLayout>
      <ModalApp />
    </>
  )
}

export default DashboardPage
