import { styled } from '@mui/system'
import { DatePicker as DatePickerBase } from '@mui/x-date-pickers/DatePicker'

export const DatePicker = styled(DatePickerBase)(({ theme }) => ({
  width: '100%',
  '& .MuiButtonBase-root': {
    color: 'black !important' /* Set the text color to black */,
  },
  '& .MuiInputBase-root': {
    backgroundColor: theme.palette.grey.grey3,
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.blue.blue6,
    width: '16px',
    height: '16px',
    paddingRight: '3px',
  },
  '& .MuiInputBase-input': {
    padding: '11px',
  },
}))
