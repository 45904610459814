import React, { useEffect, useState } from 'react'
import { ModalContent, ModalHeader, Article, TitleExpireIn, Timer, ButtonStayConnected, ButtonSignOut, ButtonContainer } from './SessionTimeOutTimer.style'
import workerInstances from '../../../../services'
import { setModal } from '../../../../redux/slices/Modal/modalSlice'
import { MODALS } from '../../../../utils/constants'
import { useDispatch } from 'react-redux'
import { logout } from '../../../../redux/slices/Auth/authSlice'
import { useNavigate } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import { Grid } from '@mui/material'

function SessionTimeOutTimer() {
  const navigate = useNavigate()
  const [content, setContent] = useState('03:00')
  const dispatch = useDispatch()
  const id = uuid()
  const workerInstance = workerInstances.WebSocketPricesInstance

  useEffect(() => {
    if (!workerInstance) return

    const receiveSessionCountdownData = (message) => {
      if (message.data.type === 'session_countdown') {
        setContent(message.data.content)
      }
      if (message.data.type === 'session_countdown' && message.data.content === '00:00') {
        handleSessionTimeout()
      }
    }

    workerInstance.addEventListener('message', receiveSessionCountdownData)

    return () => workerInstance.removeEventListener('message', receiveSessionCountdownData)
  }, [workerInstance])

  const handleSessionTimeout = () => {
    dispatch(setModal({ type: MODALS.MODAL_SESSION_TIME_OUT, isOpen: true }))
    sessionStorage.clear()
  }

  const handleCloseConnection = () => {
    dispatch(setModal({ type: MODALS.MODAL_SESSION_TIME_OUT, isOpen: false }))
    dispatch(logout(navigate))
  }

  const handleUserConnection = () => {
    workerInstance.sendEvent({
      group: 'connection',
      type: 'stay_connected',
      id,
      data: {},
    })
    dispatch(setModal({ type: MODALS.MODAL_SESSION_TIME_OUT_TIMER, isOpen: false }))
  }

  return (
    <ModalContent>
      <ModalHeader>SESSION TIMEOUT</ModalHeader>
      <Article>We’ve noticed that you’re not connected for more than 1 hour. Do you wish to still stay connected?</Article>
      <TitleExpireIn>This online session will expire in</TitleExpireIn>
      <Timer>{content}</Timer>
      <ButtonContainer container>
        <Grid item xs={12} lg={5}>
          <ButtonStayConnected variant="outlined" onClick={handleUserConnection}>
            STAY CONNECTED
          </ButtonStayConnected>
        </Grid>
        <Grid item xs={0} lg={2}></Grid>
        <Grid item xs={12} lg={5}>
          <ButtonSignOut variant="contained" onClick={handleCloseConnection}>
            SIGN OUT
          </ButtonSignOut>
        </Grid>
      </ButtonContainer>
    </ModalContent>
  )
}

export default SessionTimeOutTimer
