import { styled } from '@mui/system'
import { Button, Card, Typography, TextField, OutlinedInput, FormControl, InputLabel, IconButton } from '@mui/material'

export const MyCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.black.black3,
  boxShadow: `2px 4px 12px ${theme.palette.black.black4}`,
  borderRadius: '8px',
}))

export const Header = styled(Typography)(({ theme }) => ({
  color: theme.palette.white.white2,
  marginTop: '32px',
  marginBottom: '32px',
  textAlign: 'center',
  textTransform: 'capitalize',
}))

export const Logo = styled(Typography)(() => ({
  marginTop: 32,
  marginLeft: 'auto',
  marginRight: 'auto',
  height: '80px',
  width: '80px',
}))

export const Input = styled(TextField)(({ theme }) => ({
  color: `${theme.palette.white.white1} !important`,
  '&&': {
    backgroundColor: theme.palette.black.black1,
    padding: '0px',
    marginLeft: '48px',
    marginRight: '48px',
    marginBottom: '32px',
    border: 'none',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& input': {
      color: `${theme.palette.white.white1} !important`, // Set the text color
    },

    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .Mui-focused': {
      color: `${theme.palette.grey.grey1} !important`,
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },
  },
  '& input:-webkit-autofill': {
    color: theme.palette.white.white1,
    WebkitBoxShadow: `0 0 0 1000px ${theme.palette.grey.grey3} inset`,
  },
  '& .MuiInputLabel-root': {
    color: theme.palette.grey.grey1,
  },
  '&::placeholder': {
    color: theme.palette.grey.grey1,
  },
}))

export const SignInButton = styled(Button)(({ theme }) => ({
  width: '252px',
  height: '40px',
  backgroundColor: theme.palette.blue.blue6,
  textAlign: 'center',
  textTransform: 'uppercase',
  font: 'Ariel',
  fontWeight: 'bold',
  fontSize: theme.typography.fontSizes.m,
  letterSpacing: '0px',
  color: theme.palette.black.black3,
  marginTop: '48px',
  marginBottom: '40px',
  marginLeft: 'auto',
  marginRight: 'auto',
  '&:hover': {
    backgroundColor: theme.palette.blue.hover,
    color: theme.palette.black.black3,
  }, // Remove the border on hover
  // ... other button styles
}))

export const CustomOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
  backgroundColor: theme.palette.black.black1,
  color: theme.palette.white.white1,
  marginRight: 'auto',
  marginLeft: 'auto',
  '& input:-webkit-autofill': {
    WebkitBoxShadow: `0 0 0 1000px ${theme.palette.grey.grey3} inset`, // Set the background color
  },
  '&.Mui-focused': {
    color: `${theme.palette.white.white1} !important`,

    '& fieldset': {
      borderColor: 'transparent', // Remove border color on focus
    },
  },
  '&:hover': {
    '& fieldset': {
      borderColor: 'transparent', // Remove border color on hover
    },
  },
  '& fieldset': {
    border: 'none', // Remove default border
  },
}))

export const CustomFormControl = styled(FormControl)({
  marginRight: '48px',
  marginLeft: '48px',
  marginBottom: '40px',
})

export const CustomInputLabel = styled(InputLabel)(({ theme }) => ({
  color: theme.palette.grey.grey1,
  '&.Mui-focused': {
    color: theme.palette.grey.grey1, // Change label color when focused
  },
}))

export const CustomIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.grey.grey1,
}))
