import React from 'react'
import ErrorFeedbackOverlay from '../SharedComponents/ErrorFeedbackOverlay/ErrorFeedbackOverlay'

class ErrorBoundaryWidget extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    console.log({ error, errorInfo })
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorFeedbackOverlay errorMessage={'Something went wrong.'} height="85%" />
    }

    return this.props.children
  }
}

export default ErrorBoundaryWidget
