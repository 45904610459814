import React from 'react'
import { useSelector } from 'react-redux'
import { StyledFilterText, StyledTitle, StyledButton } from '../../Statement.style.'
import { Box } from '@mui/material'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

import './StatementTradeBlotter.css'
import { DateTimePicker } from './StatementTradeBlotter.style.'
import { useState } from 'react'
import { ENDPOINTS, REST_GATEWAY_URL } from '../../../../utils/constants'

function DrawerFilterTradeBlotter() {

  const token = useSelector((state) => state.auth.token)

  const [startDate, setStartDate] = useState(dayjs.utc().startOf('month'))
  const [endDate, setEndDate] = useState(dayjs.utc())

  const handleChangeStart = (newValue) => {
    if (isNaN(newValue.valueOf())) { return }
    setStartDate(newValue);
  }

  const handleChangeEnd = (newValue) => {
    if (isNaN(newValue.valueOf())) { return }
    setEndDate(newValue);
  }

  const GenerateStatement = () => {

    const url = `${REST_GATEWAY_URL}${ENDPOINTS.GENSTATEMENT}`
    const requestOptions = {
      method: 'POST',
      headers: { Authorization: `Bearer ${token}` },
    }
    fetch(url + "?from=" + startDate.valueOf() + "&to=" + endDate.valueOf(), requestOptions)
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `extp_statement_from_${startDate.toISOString()}_to_${endDate.toISOString()}.xlsx`); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => {
        return Promise.reject({ Error: 'Something Went Wrong', err });
      })
  }

  return (
    <Box style={{ paddingLeft: '12px', paddingRight: '12px', paddingTop: '8px' }}>
      <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
        <StyledFilterText>Generate Statement</StyledFilterText>
      </Box>
      <StyledTitle>Starting Date:</StyledTitle>
      <DateTimePicker value={startDate} onChange={handleChangeStart} disableFuture={true} disableHighlightToday={true} format="YYYY-MM-DD HH:mm:ss" />

      <StyledTitle>Ending Date:</StyledTitle>
      <DateTimePicker value={endDate} onChange={handleChangeEnd} disableFuture={true} disableHighlightToday={true} format="YYYY-MM-DD HH:mm:ss" />

      <StyledButton onClick={GenerateStatement}>
        Generate
      </StyledButton>

    </Box>
  )
}

export default DrawerFilterTradeBlotter
