import { Grid } from '@mui/material'
import React from 'react'
// import CustomTextField from '../SharedComponents/TextFields/CustomTextField'
import { Currency, CtInputContainer } from './OrderSettings.style'
import CustomInput from '../SharedComponents/CustomInput/CustomInput'
import CustomAutoComplete from '../SharedComponents/CustomAutoComplete/CustomAutoComplete'

function OrderSettings({ quantity, orderType, error, handleQuantityChange, placeholderQty, cryptoCoin, quoteCoin, products, handleProductChange, productObj }) {
  const product = productObj?.symbol ?? ''
  return (
    <CtInputContainer container justifyContent="center" alignItems="center">
      <Grid item xs={12}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={6} style={{ paddingRight: 8 }}>
            <CustomAutoComplete items={products} selectedItem={product} handleItemChange={handleProductChange} label={'Instrument'} />
          </Grid>
          <Grid item xs={6} style={{ paddingLeft: 8 }}>
            <CustomInput
              label={'Quantity'}
              value={quantity}
              onChange={(e) => handleQuantityChange(e.target.value)}
              name={'limit_price'}
              placeholder={placeholderQty() || ''}
              disabled={!productObj}
              inputType={'number'}
              InputProps={{
                style: { height: '100%', padding: 0 },
                min: 0,
                endAdornment: <Currency color="secondary">{orderType !== 'market-nominal' ? cryptoCoin : quoteCoin}</Currency>,
              }}
              error={error}
            />
          </Grid>
        </Grid>
      </Grid>
    </CtInputContainer>
  )
}

export default React.memo(OrderSettings)
