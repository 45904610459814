import React, { useState } from 'react'
import { Tooltip, Box } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { Typography } from './CopyText.style'

function CopyText(prop) {
  const { text, isFullText } = prop
  const [hover, setHover] = useState(false)
  const [tooltipText, setToolTipText] = useState(text)

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(text)
      setToolTipText('Copy Successfully')
      setTimeout(() => {
        setToolTipText(text)
      }, 2000)
    } catch (error) {
      setToolTipText('Failed to copy')
      setTimeout(() => {
        setToolTipText(text)
      }, 2000)
    }
  }

  return (
    <Box style={{ width: '120px', display: 'inline-block' }}>
      <Tooltip title={tooltipText} arrow placement="top">
        <Typography
          component={'span'}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onClick={copyToClipboard}
          style={{ cursor: 'pointer', fontWeight: '550' }}
        >
          {isFullText ? text : text.substring(0, text.length - 3).concat('...')}
          {hover && <ContentCopyIcon fontSize="small" style={{ height: '13px', width: '13px' }} />}
        </Typography>
      </Tooltip>
    </Box>
  )
}

export default CopyText
