import React from 'react'
import { FormControl, InputLabel } from '@mui/material'
import { StyledArrowIcon, StyledSelect, StyledArrowIconDisabled, StyledMenuItem } from './SelectWithArrow.style'
import { useTheme } from '@emotion/react'
import './SelectWithArrow.css'

const SelectWithArrow = ({ orderType, onChange, disabled, items, label }) => {
  const theme = useTheme()
  return (
    <FormControl style={{ width: '100%' }}>
      <InputLabel
        sx={{
          color: `${theme.palette.grey.grey1} !important`,
        }}
        id="demo-simple-select-label"
      >
        {label}
      </InputLabel>
      <StyledSelect
        value={orderType}
        onChange={onChange}
        disabled={disabled}
        label={label}
        style={{ background: theme.palette.black.black1 }}
        sx={{
          '& .MuiSelect-root': {
            background: theme.palette.black.black2,
            color: 'white',
            padding: '0px',
          },
          // '& .MuiSelect-icon': {
          //   color: 'white',
          // },
          '& .MuiInputBase-input': {
            padding: '11px',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.black.black1,
          },
        }}
        IconComponent={disabled ? StyledArrowIconDisabled : StyledArrowIcon}
      >
        {items.map((type, index) => (
          <StyledMenuItem key={index} value={type.val}>
            {type.name}
          </StyledMenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  )
}

export default SelectWithArrow
