import React from 'react'
import { Grid } from '@mui/material'
import Login from '../../components/Login/Login'
import { LoginContainer } from './Login.style'
import ModalApp from '../../components/ModalApp/ModalApp'

const LoginPage = () => {
  return (
    <LoginContainer container>
      <Grid item>
        <Login />
      </Grid>
      <ModalApp />
    </LoginContainer>
  )
}

export default LoginPage
