// Import worker
import WebSocketPricesWorker from 'workerize-loader!./websocket'

// Create an instance of WebSocketPrices
const WebSocketPricesInstance = WebSocketPricesWorker()
// const WebSocketPricesInstance = new SharedWorker('web-sockets-worker.js');
const exportingObject = {
  WebSocketPricesInstance,
}

export default exportingObject
