import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import workerInstances from '../../services'
import * as timeActions from '../../redux/slices/Time/timeSlice'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import { GreyTimeTypo, BlackTimeTypo, VersionControl } from './Time.styles'

function Time() {
  const dispatch = useDispatch()
  const time = useSelector((state) => state.time.timeFromServer)
  const workerInstance = workerInstances.WebSocketPricesInstance

  useEffect(() => {
    const receiveTime = (message) => {
      if (message.data.type === 'time') {
        dispatch(timeActions.setTime(message.data.content.timestamp))
      }
    }

    if (workerInstance) {
      workerInstance.addEventListener('message', receiveTime)
      return () => workerInstance.removeEventListener('message', receiveTime)
    }
  }, [workerInstance, dispatch])

  useEffect(() => {
    if (workerInstance) {
      workerInstance.sendEvent({
        group: 'system',
        type: 'time',
        id: uuidv4(),
        data: {},
      })
    }
  }, [workerInstance])

  const formattedTime = time && `${moment(time).utc().format('HH:mm:ss')} (UTC ${moment(time).utc().format('Z')})`

  return time ? (
    <>
      <GreyTimeTypo>{moment().format('ddd, DD MMMM yyyy')}</GreyTimeTypo>
      <BlackTimeTypo>
        {formattedTime}
        <VersionControl>v1.0.2</VersionControl>
      </BlackTimeTypo>
    </>
  ) : null
}

export default Time
