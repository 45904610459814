import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Grid, Typography, Hidden } from '@mui/material'
import * as authActions from '../../redux/slices/Auth/authSlice'
import { Container, HeaderEnv, ClockContainer, ProfilePic, DashboardMenu, ListButton, HeaderDate } from './NavBar.style'
import { ReactComponent as LogoutIcon } from '../assets/icons/logout.svg'
import { useDispatch } from 'react-redux'
import { ReactComponent as EXTPLogo } from '../assets/icons/logo.svg'
import Exposure from '../Exposure/Exposure'

const NavBar = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Container container alignContent="center" alignItems="center">
      <Grid container item xs={5} alignItems="center">
        <EXTPLogo />
      </Grid>
      <Grid item xs={2}>
        <Hidden smDown>
          <HeaderEnv>{process.env.REACT_APP_NODE_ENV}</HeaderEnv>
        </Hidden>
      </Grid>
      <Grid item xs={5} container justifyContent="flex-end">
        <Hidden xlDown>
          <Exposure />
        </Hidden>
        <Hidden mdDown>
          <ClockContainer container justifyContent="flex-end">
            <HeaderDate />
          </ClockContainer>
        </Hidden>
        <ProfilePic onClick={openMenu} src={''} />
        <DashboardMenu id="simple-menu" anchorEl={anchorEl} keepMounted style={{ top: 10 }} open={Boolean(anchorEl)} onClose={handleClose}>
          <Grid>
            <ListButton item xs={12} component={Button} onClick={() => dispatch(authActions.logout(navigate))}>
              <LogoutIcon />
              <Typography style={{ marginLeft: 14 }}>Log Out</Typography>
            </ListButton>
          </Grid>
        </DashboardMenu>
      </Grid>
    </Container>
  )
}

export default NavBar
