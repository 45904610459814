import { styled } from '@mui/system'
import { Grid } from '@mui/material'

export const CtContainer = styled(Grid)({
  height: '100%',
})

export const CtContent = styled(Grid)({
  padding: '0px 24px',
})
