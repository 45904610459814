import React from 'react'
import { GridWithMargin } from './SideButtons.style'
import { Grid } from '@mui/material'
import clsx from 'clsx'
import { StyledCustomButton } from '../SharedComponents/Buttons/CustomButton'

const SideButtons = (props) => {
  const { widgetType, advancedForm, updateAdvancedForm, displayBidAskAppBar } = props
  const bidButtonClasses = clsx({
    notChosenButton: advancedForm.side === null,
    bidButton: advancedForm.side === 'sell',
    naturalButton: advancedForm.side !== 'sell' && advancedForm.side !== null,
  })

  const askButtonClasses = clsx({
    notChosenButton: advancedForm.side === null,
    askButton: advancedForm.side === 'buy',
    naturalButton: advancedForm.side !== 'buy' && advancedForm.side !== null,
  })

  return (
    <>
      {widgetType === 'advanced' && displayBidAskAppBar ? (
        <GridWithMargin container item xs={12}>
          <Grid item xs={5.8}>
            <StyledCustomButton onClick={() => updateAdvancedForm('side', 'sell')} className={bidButtonClasses}>
              BID
            </StyledCustomButton>
          </Grid>
          <Grid item xs={0.4}></Grid>

          <Grid item xs={5.8}>
            <StyledCustomButton onClick={() => updateAdvancedForm('side', 'buy')} className={askButtonClasses}>
              ASK
            </StyledCustomButton>
          </Grid>
        </GridWithMargin>
      ) : null}
    </>
  )
}

export default React.memo(SideButtons)
