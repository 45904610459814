import { v4 as uuidv4 } from 'uuid'

export const WIDGETS = {
  SPOT_TRADER: 'SPOT TRADER',
  SPOT_BALANCES: 'SPOT BALANCES',
  BALANCE_MOVEMENT: 'BALANCE MOVEMENT',
  RFQ: 'RFQ',
  TRADES_BLOTTER: 'TRADES BLOTTER',
  MARKET_MONITOR: 'MARKET MONITOR',
  TRADING_VIEW: 'TRADING VIEW',
  TICKER_TAPE: 'TICKER TAPE',
}

export const WIDGETS_ID = {
  SPOT_TRADER: uuidv4(),
  SPOT_BALANCES: uuidv4(),
  BALANCE_MOVEMENT: uuidv4(),
  RFQ: uuidv4(),
  TRADES_BLOTTER: uuidv4(),
  MARKET_MONITOR: uuidv4(),
  TRADING_VIEW: uuidv4(),
  TICKER_TAPE: uuidv4(),
}

export const ENDPOINTS = {
  AUTH: '/auth',
  AUTH_REGISTER: '/auth/register',
  QUOTE: '/quote',
  TRADE: '/trade',
  ORDER: '/order',
  BALANCES: '/balances',
  BALANCE_MOVEMENT: '/balances/movements',
  INSTRUMENTS: '/instruments',
  EXPOSURE: '/exposure',
  GENSTATEMENT: '/generate-statement'
}

export const REST_GATEWAY_URL = process.env.REACT_APP_REST_GATEWAY_URL

export const MODALS = {
  MODAL_2FA: 'MODAL_2FA',
  MODAL_SESSION_TIME_OUT: 'MODAL_SESSION_TIME_OUT',
  MODAL_SESSION_TIME_OUT_TIMER: 'MODAL_SESSION_TIME_OUT_TIMER',
}
