import { styled } from '@mui/system'
import { Avatar, Grid, Menu, Typography } from '@mui/material'
import Time from './Time'

export const Container = styled(Grid)(({ theme }) => ({
  padding: '8px 12px',
  height: 56,
  width: '100%',
  borderBottom: `1px solid ${theme.palette.primary.border}`,
  display: 'flex',
  alignContent: 'center',
}))

export const HeaderTitle = styled(Typography)(({ theme }) => ({
  paddingLeft: 14,
  fontSize: theme.typography.fontSizes.sb,
  textTransform: 'capitalize',
  color: theme.palette.grey.grey14,
}))

export const getHeaderColor = (theme) => {
  if (process.env.REACT_APP_NODE_ENV === 'prod') {
    return theme.palette.red.rd4
  } else if (process.env.REACT_APP_NODE_ENV === 'uat') {
    return theme.palette.green.green2
  } else {
    return theme.palette.blue.blue7
  }
}

export const HeaderEnv = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  borderRadius: 4,
  padding: '7px',
  fontSize: theme.typography.fontSizes.m,
  fontWeight: 600,
  textTransform: 'uppercase',
  color: theme.palette.primary.main,
  backgroundColor: getHeaderColor(theme),
}))

export const ClockContainer = styled(Grid)(({ theme }) => ({
  borderRight: `1px solid ${theme.palette.primary.background}`,
  width: 235,
  marginRight: 8,
  paddingRight: 8,
}))

export const HeaderDate = styled(Time)({})

export const ProfilePic = styled(Avatar)(({ theme }) => ({
  width: 40,
  height: 40,
  cursor: 'pointer',
  '&.MuiAvatar-colorDefault': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.divider,
  },
}))

export const DashboardMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    minWidth: 126,
    boxShadow: `0px 2px 2px ${theme.palette.black.black6}`,
    backgroundColor: theme.palette.primary.dropdown,
  },
  '& .MuiPopover-paper': {
    maxWidth: 187,
  },
}))

export const ListButton = styled(Grid)({
  padding: '6px 16px',
  textTransform: 'lowercase',
  color: 'white',
})

export const DrawerLogo = styled(Grid)({
  height: 50,
})
