import { styled } from '@mui/system'
import { Grid, Typography } from '@mui/material'

export const CtErrorOverlayUnavailable = styled(Grid)(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  left: '0%',
  // height: 'calc(100% - 32px)',
  background: `${theme.palette.black.black5} 0% 0% no-repeat padding-box`,

  zIndex: 10,
  backdropFilter: 'blur(15px)',
}))

export const CtLoadingOverlay = styled(Typography)({
  color: 'white',
  position: 'absolute',
  top: 'calc(50% - 40px)',
  zIndex: 200,
})
