import { combineReducers } from '@reduxjs/toolkit'
import authReducer from './slices/Auth/authSlice'
import utilsReducer from './slices/Utils/utilsSlice'
import modalReducer from './slices/Modal/modalSlice'
import timeSlice from './slices/Time/timeSlice'
import exposuresSlice from './slices/Exposures/exposuresSlice'
import notificationReducer from './slices/Notification/notificationSlice'
import tradingViewReducer from './slices/TradingView/tradingViewSlice'

const appReducer = combineReducers({
  auth: authReducer,
  utils: utilsReducer,
  time: timeSlice,
  exposures: exposuresSlice,
  modal: modalReducer,
  notification: notificationReducer,
  tradingView: tradingViewReducer,
})

export default appReducer
