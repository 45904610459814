import { Grid as GridBase, Typography } from '@mui/material'
import { styled } from '@mui/system'

export const Grid = styled(GridBase)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
}))

export const StyledPriceBySide = styled(GridBase)(() => ({
  marginTop: '12px',
}))

export const GreyLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey.grey1,
  fontSize: theme.typography.fontSizes.xl,
  fontWeight: 600,
}))

export const SpanBySideColor = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.fontSizes.lg,
  fontWeight: 600,
  paddingLeft: '5px',
  '&.buy': {
    color: `${theme.palette.green.green1} !important`,
  },
  '&.sell': {
    color: `${theme.palette.red.red1} !important`,
  },
}))
