import React from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import LoginPage from '../../pages/Login/Login'
import Layout from '../Layout/Layout'
import { useSelector } from 'react-redux'
import DashboardPage from '../../pages/Dashboard/Dashboard'

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/*" element={<PrivateRoute element={<DashboardPage />} />} />
      </Routes>
    </Router>
  )
}

export default AppRouter

const isAuthenticated = (token) => {
  return !!token || !!sessionStorage.getItem('TOKEN')
}

const PrivateRoute = ({ element }) => {
  const token = useSelector((state) => state.auth.token)
  if (!isAuthenticated(token)) {
    return <Navigate to="/login" />
  }
  return <Layout>{element}</Layout>
}

PrivateRoute.propTypes = {
  element: PropTypes.node.isRequired,
}
