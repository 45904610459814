import { Grid, CircularProgress } from '@mui/material'
import { styled } from '@mui/system'

export const CtErrorOverlayUnavailable = styled(Grid)(({ height, theme }) => ({
  position: 'absolute',
  width: '100%',
  left: '0%',
  height: height ?? 'calc(100% - 32px)',
  background: `${theme.palette.black.black5} 0% 0% no-repeat padding-box`,
  zIndex: 30,
  backdropFilter: 'blur(15px)',
}))

export const CtLoadingOverlay = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.blue.blue7,
  position: 'absolute',
  top: 'calc(50% - 40px)',
  zIndex: 200,
}))
