import { Grid } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import LoadingOverlay from '../NotificationOverlays/LoadingOverlay'
import TradingButtons from './TradingButtons'
// import TypeButtons from './TypeButtons'
import workerInstances from '../../services'
import { useSelector, useDispatch } from 'react-redux'
import OrderSettings from './OrderSettings'
import AdvancedOrder from './AdvancedOrder'
import SlippageAndType from './SlippageAndType'
import SideButtons from './SideButtons'
import Levels from './Levels'
import { debounce, isEmpty } from 'lodash'
import { refreshSpotTraderAndSpotBalance } from '../../redux/slices/Utils/utilsSlice'
import { deviated_limit_price, formatNumber, validMaxDigits, validateQty, validateSlippage } from '../../utils/helperFunctions'
import Error from './Error'
import { CtContainer, CtContent } from './SpotTrader.style'
import ActionFeedbackOverlay from '../SharedComponents/ActionFeedbackOverlay/ActionFeedbackOverlay'
import { handelNotifications } from '../../redux/slices/Notification/notificationSlice'
import { v4 as uuid } from 'uuid'
import { setProduct } from '../../redux/slices/TradingView/tradingViewSlice'

let minQuantityWs = 0
let initialMinQuantityWs = true

function SpotTrader(props) {
  const { id, blotter } = props
  const dispatch = useDispatch()
  const wrapperRef = useRef(null)
  const [isLoadingCT, setIsLoadingCT] = useState(false)
  const [exceededMin, setExceededMin] = useState(false)
  const [showOverlayAnimation, setShowOverlayAnimation] = useState(null)
  const [exceededMax, setExceededMax] = useState(false)
  const [slippage, setSlippage] = useState(2)
  const [exceededMaxQtyDecimals, setExceededMaxQtyDecimals] = useState(false)
  const [dataFromWS, setDataFromWS] = useState(null)
  // const [widgetType, setWidgetType] = useState('basic')
  const widgetType = 'basic'
  const [orderType, setOrderType] = useState('market')
  const products = useSelector((state) => state?.utils?.products)
  const [productObj, setProductObj] = useState('')
  const [quantity, setQuantity] = useState(1)
  const [disableTrading, setDisableTrading] = useState(false)
  const [displayBidAskAppBar, setDisplayBidAskAppBar] = useState(true)
  const [timeInForce, setTimeInForce] = useState('FOK')
  const [advancedForm, setAdvancedForm] = useState({
    side: null,
    TIF: 'FOK',
    stop_price: '',
    limit_price: '',
    forward_start: false,
    date: null,
  })
  const [body, setBody] = useState(null)

  const memoizedProducts = useMemo(() => products, [products])
  const productName = useMemo(() => productObj?.symbol, [productObj])
  const client_order_id = useMemo(() => uuid(), [])
  const decimalsOfQuantity = useMemo(() => (productName?.length && productObj?.decimal_quantity ? Number(productObj?.decimal_quantity) : 8), [productObj])
  const cryptoCoin = useMemo(() => productName?.split('-')[0], [productName])
  const quoteCoin = useMemo(() => productName?.split('-')[1], [productName])

  const workerInstance = workerInstances.WebSocketPricesInstance

  const searchBy = useCallback(
    debounce((qty) => subToPrices(qty), 400),
    [productName]
  )

  const handleExecuteLimit = async (sideReq) => {
    if (!workerInstance || !productObj) return
    let bid = parseFloat(dataFromWS?.price?.bid?.price)
    let ask = parseFloat(dataFromWS?.price?.ask?.price)
    const side = sideReq || advancedForm.side
    let price = side === 'sell' ? bid : ask
    setIsLoadingCT(true)
    setDisplayBidAskAppBar(false)
    const requestBody = {
      instrument: productName,
      side,
      size: Number(quantity),
      type: orderType,
      tif: timeInForce,
      limit_price:
        advancedForm.limit_price !== '' && widgetType === 'advanced'
          ? Number(deviated_limit_price(advancedForm.limit_price, slippage, side))
          : deviated_limit_price(price, slippage, side),
    }
    setBody(requestBody)
    await handlePostExecute({ requestBody })
  }

  const handleExecuteMKT = async (sideReq) => {
    if (!workerInstance || !productObj) return
    setIsLoadingCT(true)
    setDisplayBidAskAppBar(false)
    const side = sideReq || advancedForm.side
    const requestBody = {
      instrument: productName,
      side,
      type: orderType,
    }
    if (orderType === 'market') {
      requestBody.size = Number(quantity)
    } else if (orderType === 'market-nominal') {
      requestBody.nominal = Number(quantity)
    }
    setBody(requestBody)
    await handlePostExecute({ requestBody })
  }

  useEffect(() => {
    if (!workerInstance) return

    const receiveSubscriptionData = (message) => {
      if (message.data.type === 'execute_order') {
        if (client_order_id === message.data.content.client_order_id) {
          getResultForExecute(message.data)
        } else if (message.data.error === true) {
          const { reject_text } = message.data.content
          dispatch(handelNotifications({ id: Date.now(), message: reject_text.replaceAll('_', ' '), type: 'error' }))
          setIsLoadingCT(false)
        }
      }
    }
    workerInstance.addEventListener('message', receiveSubscriptionData)

    return () => workerInstance.removeEventListener('message', receiveSubscriptionData)
  }, [workerInstance, body])

  useEffect(() => {
    dispatch(setProduct({ product: productName }))
  }, [productName])

  const handlePostExecute = async ({ requestBody }) => {
    const message = {
      id: client_order_id,
      type: 'execute_order',
      group: 'order',
      data: requestBody,
    }
    workerInstance.sendEvent(message)
  }

  const getResultForExecute = (response) => {
    setIsLoadingCT(false)
    const { error } = response
    const { status } = response.content
    const { side, executed_price, filled_quantity, instrument, id, nominal, reject_text } = response.content
    if (status === 'rejected' || error === true) {
      if (reject_text) {
        dispatch(handelNotifications({ id: Date.now(), message: reject_text.replaceAll('_', ' '), type: 'error' }))
      }

      setShowOverlayAnimation({
        type: 'fail',
        side: side || body.side,
        quantity,
        product: instrument || productName,
        price: body.limit_price || advancedForm.limit_price,
        tradeId: id,
      })
    } else {
      setShowOverlayAnimation({
        type: 'success',
        side: side || body.side,
        quantity: filled_quantity || quantity,
        product: instrument,
        price: executed_price || advancedForm.limit_price,
        nominal,
        tradeId: id,
      })
    }
    setTimeout(() => {
      setShowOverlayAnimation(null)
      dispatch(refreshSpotTraderAndSpotBalance(blotter))
      setDisplayBidAskAppBar(true)
    }, 3000)
  }

  const handleQuantityChange = useCallback(
    (noCommasStringVal) => {
      setQuantity(noCommasStringVal)
      setExceededState(Number(noCommasStringVal))
      setExceededMaxQtyDecimals(false)
    },
    [quantity, exceededMaxQtyDecimals]
  )

  const setExceededState = (value) => {
    if (value < Number(minQuantityWs) && orderType !== 'market-nominal') {
      setExceededMin(true)
      setExceededMax(false)
      setDisableTrading(true)
    } else if (value > Number(productObj.max_quantity) && orderType !== 'market-nominal') {
      setExceededMin(false)
      setExceededMax(true)
      setDisableTrading(true)
    } else {
      exceededMin && setExceededMin(false)
      exceededMax && setExceededMax(false)
      setDisableTrading(false)
    }
  }

  const checkExceededMaxQtyDecimals = (splittedValue) => {
    let decLen = splittedValue?.[1]?.length
    if (decLen > decimalsOfQuantity) {
      !exceededMaxQtyDecimals && setExceededMaxQtyDecimals(true)
    } else {
      exceededMaxQtyDecimals && setExceededMaxQtyDecimals(false)
    }
  }

  const updateQty = useCallback(
    (value) => {
      let noCommasStringVal = ''
      if (typeof value === 'string') {
        noCommasStringVal = value?.replace(/,/g, '')
      } else if (typeof value === 'number') {
        noCommasStringVal = formatNumber(value, 'notWidget', decimalsOfQuantity)?.replace(/,/g, '')
      }
      let splittedValue = noCommasStringVal.split('.')
      let isValid = validateQty(noCommasStringVal, decimalsOfQuantity) && validMaxDigits(splittedValue, cryptoCoin)
      if (isValid || value === '') {
        searchBy(Number(noCommasStringVal))
        handleQuantityChange(noCommasStringVal)
      } else {
        checkExceededMaxQtyDecimals(splittedValue)
      }
    },
    [quantity, exceededMaxQtyDecimals]
  )

  const placeholderQty = useCallback(() => {
    return `1.${'0'.repeat(decimalsOfQuantity)}`
  }, [])

  const handleProductChange = useCallback(
    (value) => {
      if (value === null) {
        setProductObj({ symbol: '' })
        return
      }
      setProductObj({ ...value, symbol: value.label })
      initialMinQuantityWs = true
      return
    },
    [productObj, quantity]
  )

  const updateAdvancedForm = useCallback(
    (name, value) => {
      const updated = { ...advancedForm, [name]: value }
      setAdvancedForm(updated)
    },
    [advancedForm]
  )

  const disabledExecution = useCallback(() => {
    if (orderType === 'limit' && (!quantity || !productObj || !advancedForm.TIF || !advancedForm.limit_price || !advancedForm.side)) {
      return true
    } else if (!quantity || !productObj || !advancedForm.TIF || !advancedForm.side) {
      return true
    }
  }, [quantity, advancedForm, productObj])

  const handleChangeSlippage = useCallback(
    (value) => {
      if (validateSlippage(value)) {
        setSlippage(value)
      }

      if (value === '' || value === null) {
        setDisableTrading(true)
      } else {
        setDisableTrading(false)
      }
    },
    [slippage, disableTrading]
  )

  const tradingButtonsProps = {
    handleExecuteLimit,
    handleExecuteMKT,
    dataFromWS,
    orderType,
    disableTrading,
    productObj,
    widgetType,
  }

  const advancedFormProps = {
    handleExecuteLimit,
    handleExecuteMKT,
    orderType,
  }

  // const typeButtonsProps = {
  //   setWidgetType,
  //   setOrderType,
  //   widgetType,
  // }

  const errorProps = {
    dataFromWS,
    productObj,
    exceededMin,
    exceededMax,
    exceededMaxQtyDecimals,
    authorizedMax: productObj.max_quantity,
    authorizedMin: minQuantityWs,
    decimalsOfQuantity,
  }

  const levelsProps = {
    widgetType,
    dataFromWS,
    productObj,
  }

  const sideButtonsProps = {
    updateAdvancedForm,
    widgetType,
    advancedForm,
    displayBidAskAppBar,
  }

  const productAndQtyProps = {
    handleQuantityChange: updateQty,
    placeholderQty,
    handleProductChange,
    quantity,
    error: exceededMax || exceededMin || exceededMaxQtyDecimals,
    orderType,
    cryptoCoin,
    quoteCoin,
    products: memoizedProducts,
    productObj,
  }

  const slippageAndTypeProps = {
    setTimeInForce,
    setOrderType,
    handleChangeSlippage,
    slippage,
    disableTrading,
    timeInForce,
    orderType,
    cryptoCoin,
    widgetType,
    productObj,
  }

  useEffect(() => {
    if (!workerInstance || !productObj || !quantity) return
    subToPrices()
  }, [workerInstance, productObj])

  useEffect(() => {
    if (productObj === null) return
    setIsLoadingCT(false)
  }, [productObj])

  useEffect(() => {
    if (productObj) {
      setExceededState(Number(quantity))
    }
  }, [productObj])

  useEffect(() => {
    if (memoizedProducts?.length) {
      const firstProduct = memoizedProducts[0]
      setProductObj(firstProduct)
    }
  }, [memoizedProducts])

  useEffect(() => {
    if (!workerInstance || !productObj) return

    const receiveSubscriptionData = (message) => {
      if (message.data && message.data.content && message.data?.type === 'subscription') {
        if (!isEmpty(message.data.content[productName])) {
          setDataFromWS(message.data.content[productName])
          if (initialMinQuantityWs) {
            const minQuantity = Object.keys(message.data.content[productName].level).sort((a, b) => a - b)[0]
            setQuantity(minQuantity)
            setExceededMin(false)
            setExceededMax(false)
            setDisableTrading(false)
            minQuantityWs = minQuantity
            initialMinQuantityWs = false
          }
        } else {
          setDataFromWS({})
        }
      }
    }
    workerInstance.addEventListener('message', receiveSubscriptionData)

    return () => workerInstance.removeEventListener('clickTradingSubscriptionListener', receiveSubscriptionData)
  }, [id, workerInstance, productObj])

  const subToPrices = (currentQuantity) => {
    setDataFromWS(null)
    workerInstance.sendEvent({
      id: id,
      group: 'price',
      type: 'subscription',
      data: {
        products: [].concat(productName),
        quantity: currentQuantity ?? quantity,
        level: true,
      },
    })
  }

  return (
    <CtContainer container ref={wrapperRef} alignContent="flex-start">
      <Grid item xs={12}>
        {isLoadingCT ? (
          <LoadingOverlay height={'100%'} />
        ) : (
          <>{showOverlayAnimation && <ActionFeedbackOverlay showAnimation={showOverlayAnimation} setShowOverlayAnimation={setShowOverlayAnimation} />}</>
        )}
      </Grid>
      <CtContent container>
        <Error {...errorProps} />
        {/* <TypeButtons {...typeButtonsProps} /> */}
        <TradingButtons {...tradingButtonsProps} />
        <SideButtons {...sideButtonsProps} />
        <OrderSettings {...productAndQtyProps} />
        <SlippageAndType {...slippageAndTypeProps} />
        {widgetType === 'basic' ? (
          <Levels {...levelsProps} />
        ) : (
          <AdvancedOrder advancedForm={advancedForm} updateAdvancedForm={updateAdvancedForm} disabledExecution={disabledExecution} {...advancedFormProps} />
        )}
      </CtContent>
    </CtContainer>
  )
}

export default SpotTrader
