import { Checkbox } from '@mui/material'
import { styled } from '@mui/system'
import { Card } from '@mui/material'

export const MyCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.black.black2,
}))

export const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  '&.Mui-checked': {
    color: `${theme.palette.blue.blue6} !important`,
    borderRadius: '2px',
  },
}))

export const StyledDisabledCheckbox = styled(Checkbox)(({ theme }) => ({
  '&.Mui-checked': {
    color: `${theme.palette.primary.main} !important`,
    borderRadius: '2px',
  },
  '& .MuiSvgIcon-root': {
    backgroundColor: `${theme.palette.primary.main} !important`,
    borderRadius: '4px',
  },
}))
export const RootCheckbox = styled(Checkbox)(({ theme }) => ({
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '&.Mui-checked + .MuiFormControlLabel-label': {
    color: theme.palette.primary.main,
    '&.Mui-disabled': {
      color: theme.palette.grey.grey16,
    },
  },
  '&:not(.Mui-checked) + .MuiFormControlLabel-label': {
    color: theme.palette.grey.grey2,
  },
  '& .MuiSvgIcon-root': {
    backgroundColor: `${theme.palette.primary.main} !important`,
    borderRadius: '4px',
  },
}))

export const Icon = styled(Checkbox)(({ theme }) => ({
  borderRadius: 2,
  width: 20,
  height: 20,
  backgroundColor: theme.palette.primary.main,
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: theme.palette.grey.grey16,
  },
  '&:CheckedIcon': {
    backgroundColor: theme.palette.blue.blue7,
    boxShadow: `inset 0 0 0px 1px ${theme.palette.blue.blue7}`,
    '&:before': {
      display: 'block',
      width: 20,
      height: 20,
      backgroundImage: theme.palette.checkbox.svg,
      content: '""',
    },
  },
}))

export const Indeterminate = styled(Checkbox)(({ theme }) => ({
  '&:before': {
    display: 'block',
    width: 20,
    height: 20,
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cg id='Group_5668' data-name='Group 5668' transform='translate(-1192 -379)'%3E%3Cg id='Rectangle_2656' data-name='Rectangle 2656' transform='translate(1192 379)' fill='%23${theme.palette.typo.icon}' stroke='rgba(166,183,184,0)' stroke-width='1'%3E%3Crect width='20' height='20' rx='2' stroke='none'/%3E%3Crect x='0.5' y='0.5' width='15' height='15' rx='1.5' fill='none'/%3E%3C/g%3E%3Cpath id='Line_1761' data-name='Line 1761' d='M9,.75H0A.75.75,0,0,1-.75,0,.75.75,0,0,1,0-.75H9A.75.75,0,0,1,9.75,0,.75.75,0,0,1,9,.75Z' transform='translate(1195.5 387)' fill='%23${theme.palette.typo.icon}'/%3E%3C/g%3E%3C/svg%3E")`,
    content: '""',
  },
}))
