import { Button } from '@mui/material'
import { styled } from '@mui/material/styles'

export const StyledCancelButton = styled(Button)(({ theme, width }) => ({
  fontSize: theme.typography.fontSizes.lg,
  fontWeight: 600,
  width: width || '100%',
  backgroundColor: `${theme.palette.black.black2} !important`,
  boxShadow: `0 0 0 1px  ${theme.palette.white.white1}`,
  color: `${theme.palette.white.white1} !important`,
  '&:hover': {
    color: `${theme.palette.blue.blue7} !important`,
    boxShadow: `0 0 0 1px  ${theme.palette.blue.blue7}`,
  },
}))

export const StyledCustomButton = styled(Button)(({ theme, width }) => ({
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.blue.blue7,
  fontSize: theme.typography.fontSizes.lg,
  fontWeight: 600,
  width: width || '100%',

  '&.chosenButton': {
    backgroundColor: theme.palette.purple.purple2,
    color: theme.palette.blue.blue7,
  },

  '&:focus': {
    opacity: 1,
  },

  '&.bidButton': {
    backgroundColor: theme.palette.red.red1,
    borderRadius: '3px',
    boxShadow: `0px 3px 6px ${theme.palette.primary.shadow}`,
    opacity: 1,
  },

  '&.mainButton': {
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.blue.blue7,
    },
    '&:disabled': {
      color: theme.palette.grey.grey1,
      backgroundColor: 'transparent',
      boxShadow: `inset 0 0 0 1px ${theme.palette.primary.divider}`,
    },
  },

  '&.askButton': {
    backgroundColor: theme.palette.green.green1,
    borderRadius: '3px',
    boxShadow: `0px 3px 6px ${theme.palette.primary.shadow}`,
  },

  '&.naturalButton': {
    color: theme.palette.grey.grey1,
    opacity: 1,
    backgroundColor: `${theme.palette.black.black1} !important`,
  },

  '&.notChosenButton': {
    backgroundColor: `${theme.palette.black.black2} !important`,
    color: theme.palette.white.white1,
  },

  '&.buyButton': {
    backgroundColor: `${theme.palette.green.green1} !important`,
  },

  '&.isRequestBuyButton': {
    backgroundColor: `${theme.palette.black.black3} !important`,
    boxShadow: `inset 0 0 0 3px  ${theme.palette.green.green1}`,
    color: `${theme.palette.green.green1} !important`,
  },

  '&.sellButton': {
    backgroundColor: `${theme.palette.red.red1} !important`,
  },

  '&.isRequestSellButton': {
    height: '40px',
    backgroundColor: `${theme.palette.black.black3} !important`,
    boxShadow: `inset 0 0 0 3px  ${theme.palette.red.red1}`,
    color: `${theme.palette.red.red1} !important`,
  },

  '&.currencyButton': {
    backgroundColor: `${theme.palette.black.black1} !important`,
    color: `${theme.palette.grey.grey1} !important`,
  },

  '&.baseCurrencyButton': {
    backgroundColor: `${theme.palette.orange.orange1} !important`,
    color: `black !important`,
    height: '40px',
  },

  '&.quoteCurrencyButton': {
    backgroundColor: `${theme.palette.orange.orange1} !important`,
    color: `black !important`,
    height: '40px',
  },

  '&.isRequestCurrency': {
    backgroundColor: `${theme.palette.black.black3} !important`,
    boxShadow: `inset 0 0 0 2px ${theme.palette.orange.orange1}`,
    color: `${theme.palette.orange.orange1} !important`,
  },
}))
