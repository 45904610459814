import { Grid, Typography } from '@mui/material'
import { styled } from '@mui/system'

export const Currency = styled(Typography)({
  paddingRight: 12,
})

export const CtInputContainer = styled(Grid)({
  marginTop: '16px',
})
