import { Grid, Typography, MenuItem } from '@mui/material'
import { styled } from '@mui/system'

export const ClickTradingSmallTxt = styled(Grid)(({ theme }) => ({
  fontSize: theme.typography.fontSizes.xs,
  borderRadius: 4,
}))

export const SlippageContainer = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
})

export const SlippageInnerContainer = styled(Grid)({
  height: 24,
  flexWrap: 'nowrap',
})

export const Currency = styled(Typography)({
  height: 24,
  flexWrap: 'nowrap',
})

export const SelectMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: theme.typography.fontSize,
}))

export const Bps = styled(Typography)({
  paddingRight: 12,
})
