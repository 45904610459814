import React from 'react'
import { CtErrorOverlayUnavailable, CtLoadingOverlay } from './LoadingOverlay.style'
import { Grid } from '@mui/material'

const LoadingOverlay = (props) => {
  const { position, height, marginTop = '0%' } = props
  return (
    <Grid container justifyContent="center">
      <CtErrorOverlayUnavailable style={{ height }} item xs={12}></CtErrorOverlayUnavailable>
      <CtLoadingOverlay position={position} margintop={marginTop} />
    </Grid>
  )
}

export default LoadingOverlay
