import React from 'react'
import { CtErrorOverlayUnavailable, CtLoadingOverlay } from './Overlay.styles'
import { Grid } from '@mui/material'

const LoadingOverlay = (props) => {
  return (
    <Grid container justifyContent="center">
      <CtErrorOverlayUnavailable style={{ height: props.height }} item xs={12}></CtErrorOverlayUnavailable>
      <CtLoadingOverlay />
    </Grid>
  )
}

export default LoadingOverlay
