import { styled } from '@mui/system'
import { Grid, Typography } from '@mui/material'

export const CtErrorOverlayUnavailable = styled(Grid)(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  left: '0%',
  background: `${theme.palette.black.black5} 0% 0% no-repeat padding-box`,
  zIndex: 10,
  backdropFilter: 'blur(15px)',
}))

export const CtLoadingOverlay = styled(Typography)({
  position: 'absolute',
  top: 'calc(50% - 40px)',
  zIndex: 200,
})

export const TradeAction = styled(Typography)(({ theme }) => ({
  marginLeft: 8,
  '&.valueContainer': {
    textTransform: 'uppercase',
  },
  '&.tradeActionSell': {
    textTransform: 'uppercase',
    color: theme.palette.red.red1,
  },
  '&.tradeActionBuy': {
    textTransform: 'uppercase',
    color: theme.palette.green.green1,
  },
  '&.tradeId': {
    textAlign: 'center',
  },
}))

export const OverlayContainer = styled(Grid)(({ theme }) => ({
  width: '100%',
  padding: '0px 48px',
  position: 'absolute',
  background: `${theme.palette.black.black5} 0% 0% no-repeat padding-box`,
  zIndex: 10,
  backdropFilter: 'blur(10px)',
}))

export const ErrorMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.red.red4,
  textAlign: 'center',
}))

export const WhiteColorText = styled(Typography)(({ theme }) => ({
  marginLeft: 8,
  color: theme.palette.white.white1,
}))

export const ValueContainer = styled(Typography)(({ theme }) => ({
  marginLeft: 8,
  color: theme.palette.white.white1,
}))
