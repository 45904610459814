import React, { useCallback, useEffect, useMemo, useState } from 'react'
import axios from 'axios'
import clsx from 'clsx'
import './RFQ.style.css'
import { useSelector, useDispatch } from 'react-redux'
import { refreshSpotTraderAndSpotBalance } from '../../redux/slices/Utils/utilsSlice'
import { handelNotifications } from '../../redux/slices/Notification/notificationSlice'
import { Grid } from '@mui/material'
import { StyledCancelButton, StyledCustomButton } from '../SharedComponents/Buttons/CustomButton'
import { StyledLoaderButton } from '../SharedComponents/Buttons/LoaderButton'
import LoadingOverlay from '../SharedComponents/LoadingOverlay/LoadingOverlay'
import ErrorFeedbackOverlay from '../SharedComponents/ErrorFeedbackOverlay/ErrorFeedbackOverlay'
import ActionFeedbackOverlay from '../SharedComponents/ActionFeedbackOverlay/ActionFeedbackOverlay'
import { GridWithMargin } from './RFQ.style'
import SideButtons from './SideButtons/SideButtons'
import InputWithProductButton from './InputWithProductButton/InputWithProductButton'
import CircularStatic from './CircularProgressWithLabel/CircularProgressWithLabel'
import PriceBySide from './PriceBySide/PriceBySide'
import { ENDPOINTS, REST_GATEWAY_URL } from '../../utils/constants'
import CustomAutoComplete from '../SharedComponents/CustomAutoComplete/CustomAutoComplete'
import QuantityError from './QuantityError/QuantityError'

const RFQ = ({ blotter }) => {
  const dispatch = useDispatch()

  const products = useSelector((state) => state.utils.products)
  const exposures = useSelector((state) => state.exposures.exposures)

  const memoizedProducts = useMemo(() => products, [products])
  const memoizedExposures = useMemo(() => exposures, [exposures])

  const [side, setSide] = useState('BUY')
  const [selectedProduct, setSelectedProduct] = useState('')
  const [selectedType, setSelectedType] = useState('quantity')
  const [quantity, setQuantity] = useState(0)

  const [isRequesting, setIsRequesting] = useState(false)
  const [isRFQLoading, setIsRFQLoading] = useState(false)
  const [isLoadingReqQuote, setIsLoadingReqQuote] = useState(false)

  const [priceQuote, setPriceQuote] = useState(0)
  const [quantityQuote, setQuantityQuote] = useState(0)
  const [nominal, setNominal] = useState(0)

  const [quoteError, setQuoteError] = useState(null)
  const [showOverlayAnimation, setShowOverlayAnimation] = useState(null)
  const [intervalId, setIntervalId] = useState(null)
  const [minQuantity, setMinQuantity] = useState(0)
  const [maxQuantity, setMaxQuantity] = useState(0)
  const [quantityError, setQuantityError] = useState(null)

  const getQuote = async () => {
    const params = {
      instrument: selectedProduct,
      side: side.toLowerCase(),
      [selectedType]: quantity,
    }

    try {
      setIsLoadingReqQuote(true)
      const res = await axios.get(REST_GATEWAY_URL + ENDPOINTS.QUOTE, { params })
      if (res?.data?.quote_price || res?.data?.quantity || res?.data?.quote_price || res?.data?.nominal) {
        if (selectedType === 'quantity') {
          setPriceQuote(res.data.quote_price)
          setNominal(res.data.nominal)
        }
        if (selectedType === 'nominal') {
          setQuantityQuote(res.data.quantity)
          setPriceQuote(res.data.quote_price)
        }
        return true
      } else {
        throw new Error('Quote response missing quote_price or quantity')
      }
    } catch (error) {
      handleQuoteError(error)
      return false
    }
  }

  const handleRequestForQuote = async () => {
    setIsRequesting(true)
    const hasQuote = await getQuote()

    if (hasQuote) {
      const endTime = new Date().getTime() + 7500
      const id = setInterval(() => {
        const currentTime = new Date().getTime()
        const timeDifference = endTime - currentTime
        if (timeDifference > 0) {
          const secondsLeft = Math.floor((timeDifference / 1000) % 60)
          if (secondsLeft <= 0) {
            setIsRequesting(false)
            clearInterval(id)
            setPriceQuote(0)
            setIsLoadingReqQuote(false)
          }
        } else {
          clearInterval(id)
        }
      }, 1000)
      setIntervalId(id)
    } else {
      setPriceQuote(0)
      setIsRequesting(false)
      setIsLoadingReqQuote(false)
    }
  }

  const handleProductChange = useCallback((value) => {
    if (value?.label) {
      setSelectedProduct(value.label)
    }
  }, [])

  const handleQuoteError = (error) => {
    const errorMessage = error?.response?.data?.error || 'Could not get quote'
    dispatch(handelNotifications({ id: Date.now(), message: errorMessage, type: 'error' }))
    setQuoteError('Cannot get quote')
    setTimeout(() => {
      setQuoteError(null)
      setIsLoadingReqQuote(false)
      setIsRequesting(false)
    }, 3000)
  }

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value)
  }

  const handleSide = (value) => {
    setSide(value)
  }

  const handleExecute = async () => {
    if (selectedType === 'quantity') {
      await executeQuantityType()
    } else {
      await executeNominalType()
    }
  }

  const handleChangeQuantity = (value) => {
    validateQuantity(value)
  }

  const validateQuantity = (value) => {
    if (minQuantity && maxQuantity && value) {
      if (value < minQuantity) {
        setQuantityError(`Validation failed : min quantity ${minQuantity}`)
      } else if (value > maxQuantity) {
        setQuantityError(`Validation failed : max quantity ${maxQuantity}`)
      } else {
        setQuantityError(null)
      }
    }
    setQuantity(value)
  }

  const cancelRFQ = () => {
    setIsRequesting(false)
    clearInterval(intervalId)
    setPriceQuote(0)
    setIsLoadingReqQuote(false)
  }

  const resetAnimation = () => {
    setTimeout(() => {
      reset()
      dispatch(refreshSpotTraderAndSpotBalance(blotter))
    }, 3000)
  }

  const reset = () => {
    setIsRequesting(false)
    clearInterval(intervalId)
    // setSide('BUY')
    setQuantityQuote(0)
    setNominal(0)
    setPriceQuote(0)
    setShowOverlayAnimation(null)
    setIsLoadingReqQuote(false)
  }

  useEffect(() => {
    if (memoizedProducts?.length > 0) {
      setSelectedProduct(products[0]?.symbol || '')
    }
  }, [memoizedProducts])

  useEffect(() => {
    if (memoizedProducts?.length > 0) {
      if (selectedType === 'quantity') {
        const foundObject = memoizedProducts.find((item) => item.symbol === selectedProduct)
        if (foundObject?.min_quantity && foundObject?.max_quantity) {
          setMinQuantity(foundObject.min_quantity)
          setMaxQuantity(foundObject.max_quantity)
          setTimeout(() => {
            handleChangeQuantity(foundObject.min_quantity)
          }, 0)
        }
      } else {
        const quote = selectedProduct.split('-')[1]
        const foundObject = memoizedExposures.find((item) => item.asset === quote)
        if (foundObject?.max_exposure) {
          setMinQuantity(1)
          setMaxQuantity(foundObject.max_exposure)
          setTimeout(() => {
            handleChangeQuantity(1)
          }, 0)
        }
      }
    }
  }, [memoizedProducts, selectedType, selectedProduct, minQuantity, maxQuantity])

  const executeQuantityType = async () => {
    const reqBody = {
      order_type: 'limit',
      side: side.toLowerCase(),
      instrument: selectedProduct,
      limit_price: priceQuote,
      tif: 'FOK',
      quantity: Number(quantity),
    }

    try {
      setIsRFQLoading(true)
      const res = await axios.post(REST_GATEWAY_URL + ENDPOINTS.ORDER, reqBody)
      if (res?.data.response) {
        setIsRFQLoading(false)

        const { status } = res.data.response
        const { side, executed_price, filled_quantity, instrument, id, nominal, reject_text } = res.data.response
        const { limit_price, side: reqSide } = res.data.request
        if (status === 'rejected') {
          if (reject_text) {
            dispatch(handelNotifications({ id: Date.now(), message: reject_text, type: 'error' }))
          }
          setShowOverlayAnimation({
            type: 'fail',
            side: reqSide,
            quantity,
            product: instrument,
            price: limit_price,
            tradeId: id,
            nominal,
            isRfq: true,
          })
          resetAnimation()
        } else {
          setShowOverlayAnimation({
            type: 'success',
            side,
            quantity: filled_quantity,
            product: instrument,
            price: executed_price,
            tradeId: id,
            nominal,
            isRfq: true,
          })
          resetAnimation()
        }
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.error || 'Could not execute order'
      dispatch(handelNotifications({ id: Date.now(), message: errorMessage, type: 'error' }))
      setShowOverlayAnimation({
        type: 'fail',
        isRfq: true,
      })
      setIsRFQLoading(false)
      resetAnimation()
    }
  }

  const executeNominalType = async () => {
    const reqBody = {
      order_type: 'limit',
      side: side.toLowerCase(),
      instrument: selectedProduct,
      limit_price: priceQuote,
      tif: 'FOK',
      quantity: Number(quantityQuote),
    }

    try {
      setIsRFQLoading(true)
      const res = await axios.post(REST_GATEWAY_URL + ENDPOINTS.ORDER, reqBody)
      if (res?.data.response) {
        setIsRFQLoading(false)

        const { status } = res.data.response
        const { side, executed_price, filled_quantity, instrument, id, nominal, reject_text } = res.data.response
        const { limit_price, side: reqSide } = res.data.request
        if (status === 'rejected') {
          if (reject_text) {
            dispatch(handelNotifications({ id: Date.now(), message: reject_text, type: 'error' }))
          }
          setShowOverlayAnimation({
            type: 'fail',
            side: reqSide,
            quantity,
            product: instrument,
            price: limit_price,
            tradeId: id,
            nominal,
            isRfq: true,
          })
          resetAnimation()
        } else {
          setShowOverlayAnimation({
            type: 'success',
            side,
            quantity: filled_quantity,
            product: instrument,
            price: executed_price,
            tradeId: id,
            nominal,
            isRfq: true,
          })
          resetAnimation()
        }
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.error || 'Could not execute order'
      dispatch(handelNotifications({ id: Date.now(), message: errorMessage, type: 'error' }))
      setShowOverlayAnimation({
        type: 'fail',
        isRfq: true,
      })
      setIsRFQLoading(false)
      resetAnimation()
    }
  }

  return (
    <>
      {!memoizedProducts ? (
        <LoadingOverlay />
      ) : (
        <Grid container>
          <Grid item xs={12}>
            {isRFQLoading && <LoadingOverlay height="100%" />}
            {quoteError && <ErrorFeedbackOverlay errorMessage={quoteError} height="100%" />}
            {showOverlayAnimation && <ActionFeedbackOverlay showAnimation={showOverlayAnimation} />}
          </Grid>

          <SideButtons handleSide={handleSide} side={side} isRequesting={isRequesting} />

          <GridWithMargin container>
            <Grid item xs={4.5}>
              <CustomAutoComplete items={memoizedProducts} selectedItem={selectedProduct} handleItemChange={handleProductChange} isRequesting={isRequesting} label={'Instrument'} />
            </Grid>

            <Grid item xs={0.2}></Grid>

            <Grid item xs={7.3}>
              <InputWithProductButton
                handleTypeChange={handleTypeChange}
                quantity={quantity}
                selectedType={selectedType}
                selectedProduct={selectedProduct}
                products={memoizedProducts}
                handleChangeQuantity={handleChangeQuantity}
                isRequesting={isRequesting}
                quantityError={quantityError}
              />
            </Grid>

            <Grid container item xs={12}>
              <QuantityError quantityError={quantityError} />
            </Grid>

            <Grid item xs={12}>
              <PriceBySide
                isRequesting={isRequesting}
                priceQuote={priceQuote}
                selectedType={selectedType}
                selectedProduct={selectedProduct}
                side={side}
                nominal={nominal}
                quantityQuote={quantityQuote}
              />
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                width: '100%',
                padding: '5%',
              }}
            >
              {!priceQuote ? (
                <StyledLoaderButton
                  loading={isLoadingReqQuote}
                  onClick={handleRequestForQuote}
                  variant="outlined"
                  disabled={quantityError || !memoizedProducts.length || !quantity}
                >
                  Request For Quote
                </StyledLoaderButton>
              ) : (
                <>
                  {priceQuote !== 0 && (
                    <Grid container>
                      <Grid item xs={4}>
                        <StyledCancelButton onClick={cancelRFQ} variant="outlined">
                          Cancel
                        </StyledCancelButton>
                      </Grid>
                      <Grid item xs={1}></Grid>
                      <Grid item xs={7}>
                        <StyledCustomButton onClick={handleExecute} variant="outlined" disabled={false} className={clsx('mainButton')}>
                          {isRequesting ? <CircularStatic /> : null}
                          Execute
                        </StyledCustomButton>
                      </Grid>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </GridWithMargin>
        </Grid>
      )}
    </>
  )
}

export default RFQ
