import axios from 'axios'

export function setAuthToken(token) {
  if (token) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
  } else {
    delete axios.defaults.headers.common['Authorization']
  }
}

export const setInterceptors = (logUserOut, history) => {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401 && window.location.pathname !== '/login/verification') {
        delete axios.defaults.headers.common['Authorization']
        logUserOut()
      } else {
        history.goBack()
      }
    }
  )
}
