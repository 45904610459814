import { styled } from '@mui/system'
import { Box, Typography, Divider, IconButton as IconButtonBase } from '@mui/material'
import ReplayIcon from '@mui/icons-material/Replay'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import { Handshake } from '@mui/icons-material'

export const Widget = styled(Box)({})

export const IconButton = styled(IconButtonBase)({
  padding: '0 8px',
})

export const WidgetHeaderStyled = styled(Typography)(({ theme }) => ({
  backgroundColor: theme.palette.black.black3,
  padding: '5px 10px',
  fontSize: theme.typography.fontSizes.lg,
  color: theme.palette.grey.grey1,
}))

export const WidgetHeaderDivider = styled(Divider)(({ theme }) => ({
  backgroundColor: theme.palette.primary.divider,
}))

export const WidgetContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
})

export const CustomReplayButton = styled(ReplayIcon)(({ theme }) => ({
  backgroundColor: theme.palette.black.black3,
  color: theme.palette.orange.orange1,
  width: '20px',
  height: '20px',
}))

export const CustomFilterButton = styled(FilterAltIcon)(({ theme, isopendrawerfilter }) => {
  let color = theme.palette.grey.grey1
  if (isopendrawerfilter === 'true') {
    color = theme.palette.blue.blue6
  }

  return {
    color,
    width: '20px',
    height: '20px',
  }
})

export const CustomStatementButton = styled(Handshake)(({ theme, isopenstatement }) => {
  let color = theme.palette.grey.grey1
  if (isopenstatement === 'true') {
    color = theme.palette.blue.blue6
  }
  return {
    color,
    width: '20px',
    height: '20px',
  }
})