import React from 'react'
import { ModalContent, Title, Article, ButtonStayConnected } from './SessionTimeOut.style'
import { logout } from '../../../../redux/slices/Auth/authSlice'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setModal } from '../../../../redux/slices/Modal/modalSlice'
import { MODALS } from '../../../../utils/constants'

function SessionTimeOut() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleCloseConnection = () => {
    dispatch(setModal({ type: MODALS.MODAL_SESSION_TIME_OUT, isOpen: false }))
    dispatch(logout(navigate))
  }

  return (
    <ModalContent>
      <Title>This session timed out</Title>
      <Article>We terminated it and signed you out of your account</Article>
      <ButtonStayConnected variant="contained" onClick={handleCloseConnection}>
        SIGN BACK IN
      </ButtonStayConnected>
    </ModalContent>
  )
}

export default SessionTimeOut
