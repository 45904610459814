import React from 'react'
import { CustomTableRowHeader } from '../../SimpleTable.style'

function TableBodyRow(prop) {
  const { rows = [] } = prop
  return (
    <>
      {rows.map((element, id) => (
        <CustomTableRowHeader key={id}>
          {Object.values(element).map((v, id) => (
            <React.Fragment key={id}>{v}</React.Fragment>
          ))}
        </CustomTableRowHeader>
      ))}
    </>
  )
}

export default TableBodyRow
