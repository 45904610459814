import React from 'react'
import { Tooltip } from '@mui/material'
import moment from 'moment'
import { TextColor, Typography } from './DateTooltip.style'

function DateTooltip(prop) {
  const { date } = prop
  const time = moment(date).utc().format('YYYY/MM/DD HH:mm:ss') === 'Invalid date' ? '' : moment(date).utc().format('YYYY/MM/DD')
  const clock = moment(date).utc().format('HH:mm:ss')
  if (time !== '') {
    return (
      <Tooltip
        style={{ background: '' }}
        disableFocusListener
        title={
          <React.Fragment>
            <Typography component={'div'} style={{ display: 'flex', gap: '10px' }}>
              {time} {clock}
              <TextColor>UTC +00:00</TextColor>
            </Typography>
          </React.Fragment>
        }
        arrow
        placement="top"
      >
        <Typography component={'div'}>
          <Typography component={'span'}>{time}</Typography>
          <Typography component={'span'} style={{ fontWeight: '550' }}>
            {' '}
            {clock}*
          </Typography>
        </Typography>
      </Tooltip>
    )
  } else <></>
}

export default DateTooltip
