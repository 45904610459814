import React, { useState } from 'react'
import { Grid } from '@mui/material'
import clsx from 'clsx'
import { numberWithCommas } from '../../utils/helperFunctions'
import {
  ButtonsContainer,
  ClickTradingDiffRelative,
  ClickTradingDiffAbsolute,
  ClickTradingDiffTxt,
  PricesContainer,
  CustomSellBottom,
  CustomBuyBottom,
  ClickTradingDecimal,
  ClickTradingPrice,
} from './TradingButtons.style'

function TradingButtons(props) {
  const { dataFromWS, orderType, handleExecuteLimit, handleExecuteMKT, disableTrading, productObj, widgetType } = props

  const disabledBtns = disableTrading || !dataFromWS || !Object.keys(dataFromWS).length || (dataFromWS.filter && !dataFromWS.price) || !productObj
  const [lastTouchTime, setLastTouchTime] = useState(0)

  const priceValue = (type) => {
    const valueFromWS = dataFromWS?.price?.[type]?.price || ''
    const splitValue = valueFromWS?.split?.('.')

    const pricesClasses = clsx({
      ClickTradingBidPrice: type === 'bid',
      NumbersFontBidAdvanced: type === 'bid' && widgetType === 'advanced',
      ClickTradingAskPrice: type === 'ask',
      NumbersFontAskAdvanced: type === 'ask' && widgetType === 'advanced',
    })

    return (
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={12} id="price-btn-text-id">
          <ClickTradingPrice className={pricesClasses}>{numberWithCommas(splitValue?.[0])}</ClickTradingPrice>
          {splitValue?.[1] && <ClickTradingDecimal className={pricesClasses}>{`.${splitValue?.[1]}`}</ClickTradingDecimal>}
        </Grid>
      </Grid>
    )
  }

  const handleTouchStart = (side) => {
    const currentTime = new Date().getTime()
    const delay = 300 // Adjust the delay as needed

    if (currentTime - lastTouchTime < delay) {
      // Double touch detected
      orderType === 'limit' ? handleExecuteLimit(side) : handleExecuteMKT(side)
      setLastTouchTime(0)
    }

    setLastTouchTime(currentTime)
  }

  const sellButtonClasses = clsx({
    ButtonSellAdvanced: widgetType === 'advanced',
  })

  const buyButtonClasses = clsx({
    ButtonBuyAdvanced: widgetType === 'advanced',
  })

  return (
    <ButtonsContainer item xs={12}>
      <PricesContainer container justifyContent="center">
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6} id="price-btn-container-id" style={{ position: 'relative', paddingRight: 8 }}>
              <CustomSellBottom
                disabled={disabledBtns || widgetType === 'advanced'}
                id="sellButton"
                className={sellButtonClasses}
                style={{ width: '100%' }}
                onTouchStartCapture={() => handleTouchStart('sell')}
                onDoubleClick={() => (orderType === 'limit' ? handleExecuteLimit('sell') : handleExecuteMKT('sell'))}
                variant="contained"
              >
                <Grid container direction="column">
                  <Grid item>{priceValue('bid')}</Grid>
                  <Grid container justifyContent="flex-start">
                    <Grid item></Grid>
                  </Grid>
                </Grid>
              </CustomSellBottom>
            </Grid>
            <Grid item xs={6} style={{ position: 'relative', paddingLeft: 8, width: '100%' }}>
              <CustomBuyBottom
                disabled={disabledBtns || widgetType === 'advanced'}
                id="buyButton"
                onTouchStartCapture={() => handleTouchStart('buy')}
                onDoubleClick={() => (orderType === 'limit' ? handleExecuteLimit('buy') : handleExecuteMKT('buy'))}
                className={buyButtonClasses}
                style={{ width: '100%' }}
                variant="contained"
              >
                <Grid container direction="column">
                  <Grid item>{priceValue('ask')}</Grid>
                  <Grid container justifyContent="flex-end">
                    <Grid item></Grid>
                  </Grid>
                </Grid>
              </CustomBuyBottom>
            </Grid>
          </Grid>
        </Grid>
      </PricesContainer>
      <ClickTradingDiffRelative>
        <ClickTradingDiffAbsolute container>
          <Grid item xs={4.25} />
          <Grid item xs={3.5}>
            <ClickTradingDiffTxt>{dataFromWS?.price?.spread}</ClickTradingDiffTxt>
          </Grid>
          <Grid item xs={4.25} />
        </ClickTradingDiffAbsolute>
      </ClickTradingDiffRelative>
    </ButtonsContainer>
  )
}

export default TradingButtons
