import { Grid } from '@mui/material'
import React from 'react'
import STTable from './STTable'
import LoadingOverlay from '../SharedComponents/LoadingOverlay/LoadingOverlay'

function Levels(props) {
  const { dataFromWS, productObj } = props

  const stTableProps = {
    dataFromWS,
  }

  return (
    <Grid container justifyContent="center">
      {dataFromWS ? <STTable {...stTableProps} /> : <>{productObj?.symbol !== '' && <LoadingOverlay position={'static'} marginTop={'20%'} />}</>}
    </Grid>
  )
}

export default Levels
