import { styled } from '@mui/system'
import { Select, MenuItem } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

export const StyledSelect = styled(Select)(({ theme }) => ({
  color: `${theme.palette.white.white1} !important`,
  '& .Mui-disabled': {
    backgroundColor: `${theme.palette.black.black3} !important`,
  },
  '& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled': {
    boxShadow: `inset 0 0 0 1px ${theme.palette.primary.border} !important`,
  },
  '& .MuiPaper-root': {
    backgroundColor: `${theme.palette.black.black2} !important`,
    '& ul': {
      // Increase specificity by targeting the ul inside the Paper
      backgroundColor: `${theme.palette.black.black2} !important`,
    },
  },
}))

export const StyledArrowIcon = styled(ExpandMoreIcon)(({ theme }) => ({
  color: `${theme.palette.blue.blue7} !important`,
  marginLeft: '2px',
  marginRight: '6px',
}))

export const StyledArrowIconDisabled = styled(ExpandMoreIcon)(({ theme }) => ({
  color: `${theme.palette.grey.grey1} !important`,
  marginLeft: '2px',
  marginRight: '6px',
}))

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  color: 'white',
  textTransform: 'capitalize',
  backgroundColor: theme.palette.black.black2,
  borderBottom: `2px solid ${theme.palette.grey.grey8}`,
}))
