import React from 'react'
import { styled } from '@mui/system'
import { Modal as BaseModal, Box } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { setModal } from '../../redux/slices/Modal/modalSlice'
import { MODALS } from '../../utils/constants'
import SessionTimeOut from './children/SessionTimeOut/SessionTimeOut'
import SessionTimeOutTimer from './children/SessionTimeOutTimer/SessionTimeOutTimer'
import TwoFactorAuthentication from './children/TwoFactorAuthentication/TwoFactorAuthentication'

function ModalApp() {
  const modal = useSelector((state) => state.modal)
  const dispatch = useDispatch()

  const handleClose = (event, reason) => {
    if (reason === 'backdropClick' && modal.type === MODALS.MODAL_SESSION_TIME_OUT) return
    else {
      const copyModal = { ...modal, isOpen: false }
      dispatch(setModal(copyModal))
    }
  }

  const modalBody = () => {
    switch (modal.type) {
      case MODALS.MODAL_2FA:
        return <TwoFactorAuthentication />
      case MODALS.MODAL_SESSION_TIME_OUT:
        return <SessionTimeOut />
      case MODALS.MODAL_SESSION_TIME_OUT_TIMER:
        return <SessionTimeOutTimer />
      default:
        return <></>
    }
  }

  return (
    <div>
      <Modal open={modal.isOpen} onClose={handleClose}>
        <Box>{modalBody()}</Box>
      </Modal>
    </div>
  )
}

const Modal = styled(BaseModal)(({ theme }) => ({
  position: 'fixed',
  zIndex: 1300,
  right: 0,
  bottom: 0,
  top: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.black.black2,
  '&.base-Modal-hidden': {
    visibility: 'hidden',
  },
  '& > .MuiBackdrop-root': {
    backdropFilter: 'blur(10px)',
  },
  '@media (max-width: 600px)': {
    '& .MuiPaper-root': {
      margin: 0,
      height: 'max-content',
      width: 'max-content',
      borderRadius: 0,
    },
  },
}))

export default ModalApp
