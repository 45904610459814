import { createSlice } from '@reduxjs/toolkit'

// Slice
export const tradingViewSlice = createSlice({
  name: 'tradingView',
  initialState: {
    product: 'btc-usd',
  },

  reducers: {
    setProduct: (state, action) => {
      if (action?.payload?.product) {
        state.product = action.payload.product
      }
    },
  },
})

export const { setProduct } = tradingViewSlice.actions

export default tradingViewSlice.reducer
