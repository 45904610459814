import React, { memo, useState } from 'react'
import { ENDPOINTS, REST_GATEWAY_URL, WIDGETS, WIDGETS_ID } from '../../utils/constants'
import CustomTable from '../Table/SimpleTable'
import useAxios from '../../hooks/useAxios'
import { StyledType, StyledTableCell } from '../Table/SimpleTable.style'
import { useTheme } from '@emotion/react'
import LoadingOverlay from '../NotificationOverlays/LoadingOverlay'
import ShortText from '../SharedComponents/ShortText/ShortText'
import CopyText from '../SharedComponents/CopyText/CopyText'
import DateTooltip from '../SharedComponents/DateTooltip/DateTooltip'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { handelNotifications } from '../../redux/slices/Notification/notificationSlice'
import { logout } from '../../redux/slices/Auth/authSlice'
import { numberWithCommas } from '../../utils/helperFunctions'

function BalanceMovement() {
  const theme = useTheme()
  const [pagination, setPagination] = useState({ count: 20, page: 1 })
  const [scrollEffect, setScrollEffect] = useState(true)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const columns = ['Id', 'Trade id', 'Type', 'Asset', 'Amount', 'Date']
  const [params] = useState({
    count: 20,
    page: 1,
  })

  const resetPagination = () => {
    setPagination({ count: 20, page: 1 })
  }

  const [response, setResponse] = useAxios(
    {
      endPoint: ENDPOINTS.BALANCE_MOVEMENT,
      method: 'GET',
      params,
      setLoading,
      widget: WIDGETS.BALANCE_MOVEMENT,
    },
    { resetState: [resetPagination] }
  )

  const onScrollHandel = async (event) => {
    const e = event.target
    if (Math.abs(e.scrollHeight - e.scrollTop - e.clientHeight) < 2 && response.data.pageinfos.total_count > response.data.data.length && scrollEffect) {
      const url = `${REST_GATEWAY_URL}${ENDPOINTS.BALANCE_MOVEMENT}`
      try {
        setScrollEffect(false)
        const apiResult = await axios.get(url, { params: { count: 20, page: ++pagination.page } })
        apiResult.data.data = response.data.data.concat(apiResult.data.data)
        setResponse({ status: apiResult.status, data: apiResult.data })
        setPagination({ count: 20, page: ++pagination.page })
        if (apiResult.data.data.length !== apiResult.data.pageinfos.total_count) {
          setScrollEffect(true)
        }
      } catch (error) {
        console.log(error)
        if (error.response && error.response.status === 401) {
          navigate('/login')
          dispatch(logout())
          dispatch(
            handelNotifications({
              id: Date.now(),
              message: `Please Login`,
              type: 'error',
            })
          )

          return
        }
        dispatch(
          handelNotifications({
            id: Date.now(),
            message: `Failed to get ${WIDGETS.BALANCE_MOVEMENT.replace('_', ' ')} data`,
            type: 'error',
          })
        )
      }
    }
  }

  const balanceMovementData = () => {
    if (response.status === 200) {
      return response.data.data.map(({ asset, amount, id, trade_id, date, type }) => ({
        id: (
          <StyledTableCell component="td" scope="cell" align="center">
            <CopyText isFullText={true} text={JSON.stringify(id)} />
          </StyledTableCell>
        ),
        trade_id: (
          <StyledTableCell component="td" scope="cell" align="center">
            <CopyText isFullText={true} text={JSON.stringify(trade_id)} />
          </StyledTableCell>
        ),
        type: (
          <StyledTableCell component="td" scope="cell" align="center" style={{ padding: 0 }}>
            <StyledType order_type={type}>{type}</StyledType>
          </StyledTableCell>
        ),
        asset: (
          <StyledTableCell component="td" scope="cell" align="center">
            {asset}
          </StyledTableCell>
        ),
        amount:
          +amount > 0 ? (
            <StyledTableCell component="td" scope="cell" align="center" color={theme.palette.green.green1}>
              <ShortText text={numberWithCommas(amount)} char={'*'} color={theme.palette.green.green1} />
            </StyledTableCell>
          ) : amount === 0 ? (
            <StyledTableCell component="td" scope="cell" align="center" color={theme.palette.grey.grey1}>
              <ShortText text={amount} char={'*'} />
            </StyledTableCell>
          ) : (
            <StyledTableCell component="td" scope="cell" align="center" color={theme.palette.red.red1}>
              <ShortText text={numberWithCommas(amount)} char={'*'} color={theme.palette.red.red1} />
            </StyledTableCell>
          ),
        date: (
          <StyledTableCell component="td" scope="cell" align="center" color={theme.palette.grey.grey1}>
            <DateTooltip date={date} />
          </StyledTableCell>
        ),
      }))
    } else {
      return []
    }
  }

  return (
    <>
      {loading === false ? (
        <CustomTable rows={balanceMovementData()} columns={columns} id={WIDGETS_ID.TRADES_BLOTTER} onScrollHandel={onScrollHandel} />
      ) : (
        <LoadingOverlay height={'calc(20% - 47px)'} />
      )}
    </>
  )
}

export default memo(BalanceMovement)
