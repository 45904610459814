import { styled } from '@mui/system'
import { Divider, Tab, Tabs as TabsBase, Box } from '@mui/material'
import ReplayIcon from '@mui/icons-material/Replay'

export const WidgetHeaderStyled = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  padding: 0,
}))

export const BoxIcons = styled(Box)(() => ({
  display: 'flex',
}))

export const Tabs = styled(TabsBase)(({ theme }) => ({
  fontSize: theme.typography.fontSizes.lg,
  color: theme.palette.grey.grey1,
  minHeight: 34,
  '.MuiTabs-indicator': {
    height: 0,
  },
}))

export const TabStyled = styled(Tab)(({ theme }) => ({
  fontSize: theme.typography.fontSizes.lg,
  color: theme.palette.grey.grey1,
  minHeight: 34,
  padding: '0 40px',
}))

export const TabStyledSelect = styled(Tab)(({ theme }) => ({
  fontSize: theme.typography.fontSizes.lg,
  color: `${theme.palette.blue.blue7} !important`,
  borderBottom: `2px solid ${theme.palette.blue.blue7}`,
  padding: '0 40px',
  minHeight: 0,
}))

export const WidgetHeaderDivider = styled(Divider)(({ theme }) => ({
  backgroundColor: theme.palette.primary.divider,
}))

export const CustomReplayButton = styled(ReplayIcon)(({ theme }) => ({
  backgroundColor: theme.palette.black.black3,
  color: theme.palette.orange.orange1,
  width: '20px',
  height: '20px',
}))
