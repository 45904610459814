import { styled } from '@mui/system'
import { TextField } from '@mui/material'

const CustomTextField = styled(TextField)(({ theme }) => ({
  width: '100%',

  '& ::-webkit-input-placeholder': {
    // fontWeight: 300,
    color: theme.palette.typo.text,
    opacity: 0.6,
  },
  '& :-webkit-autofill': {
    transitionDelay: '9999s',
  },
  '& .MuiInputLabel-shrink': {
    fontSize: `${theme.typography.fontSizes.lg} !important`,
    color: `${theme.palette.grey.grey1} !important`,
    transform: 'translate(14px, -9px) scale(0.75) !important', // Transformed state for shrink effect
  },
  '& .MuiInputBase-root': {
    backgroundColor: `${theme.palette.black.black1} !important`,
    color: theme.palette.typo.default,
    height: 40,
    fontSize: theme.typography.fontSizes.m,
    alignItems: 'center',
  },
  '& .MuiInputBase-root.Mui-disabled': {
    color: `${theme.palette.typo.text} !important`,
    backgroundColor: `${theme.palette.black.black3} !important`,
  },
  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: theme.palette.grey.grey1,
  },
  '& .MuiInputBase-input': {
    padding: '1px 12px',
  },
  '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input': {
    padding: '6px 0 4px',
  },
  '& .MuiFormLabel-root, & .MuiFormLabel-root.Mui-focused': {
    color: theme.palette.grey.grey1,
  },
  '& .MuiInputLabel-formControl.MuiInputLabel-shrink': {
    transform: 'translate(0px, -18px) scale(1)',
    fontSize: theme.typography.fontSize,
    '&.Mui-error': {
      color: theme.palette.red.red2,
    },
  },
  '& .MuiInputBase-fullWidth': {
    width: '100%',
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: theme.palette.primary.background,

    borderRadius: '4px',
    '& fieldset': {
      borderColor: theme.palette.black.black1,
    },
    '&:hover fieldset, &.Mui-focused fieldset': {
      borderColor: theme.palette.black.black2,
      borderWidth: 1,
    },
    '&.Mui-disabled fieldset': {
      backgroundColor: 'transparent',
      borderColor: theme.palette.black.black2,
      borderWidth: 1,
    },
    '&:hover.Mui-error, &.Mui-error fieldset': {
      borderColor: theme.palette.red.red4,
      borderWidth: 1,
    },
    // '& .MuiSelect-icon': {
    //     '& path': {
    //         stroke: theme.palette.blue.blue7
    //     }
    // },
    '& .MuiSelect-icon.Mui-disabled': {
      borderColor: 'transparent',
      padding: '0',
      '& path': {
        fill: theme.palette.secondary.main,
      },
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    '& > *': {
      maxWidth: '0px',
    },
  },
  '& .MuiOutlinedInput-input': {
    padding: '10.5px 12px',
    lineHeight: 1.6,
  },
  '& .MuiOutlinedInput-adornedStart': {
    paddingLeft: 8,
  },
  '& .MuiOutlinedInput-adornedEnd': {
    paddingRight: 8,
  },
  '& .MuiFormHelperText-root.Mui-error': {
    whiteSpace: 'nowrap',
    color: theme.palette.red.red4,
  },
  '& .MuiFormHelperText-contained': {
    margin: '0 1px',
  },
  '& .MuiInputAdornment-positionEnd': {
    margin: '0px 8px',
  },
  // '& .MuiSelect-iconOutlined': {
  //     color: theme.palette.blue.blue7,
  //     right: 7,
  //     '& > g > path': {
  //         stroke: theme.palette.blue.blue7
  //     }
  // },
  '& .MuiSelect-select:focus': {
    backgroundColor: 'transparent',
  },
  '& .MuiSelect-icon': {
    marginTop: 7,
    right: 12,
    // '& > g > path': {
    //     stroke: theme.palette.blue.blue7
    // }
  },
  '&.typeSmall': {
    '& .MuiInputBase-root': {
      fontSize: theme.typography.fontSize,
    },
    '& .MuiOutlinedInput-input': {
      padding: '6.5px 5px',
    },
  },
}))

export default CustomTextField
