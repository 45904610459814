const theme = {
  primary: {
    main: '#2B2B2B',
    divider: '#7A9091',
    border: '#4C4C4C',
    background: '#4C4C4C',
    buttonHover: '#33CBB4',
    dropdown: '#181818',
    darkSecondary: '#1A263B',
    shadow: '#00000029',
    tabs: '#1D2A41',
    icon: '#8692B8',
    success: '#00DB6E',
  },
  secondary: {
    main: '#7A9091',
  },
  text: {
    primary: '#E4E7EB',
    secondary: '#8D96B0',
  },
  checkbox: {
    vtick: '#ffffff',
    vtickImage: 'ffffff',
    indeterminate: '2175E0',
    svg:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23000000'/%3E%3C/svg%3E\") ",
  },
  status: {
    pending: '#F4BF2D',
    settled: '#00D4BE',
    processing: '#5A74FF',
    canceled: '#FF5771',
    filled: '#0AB17E',
    rejected: '#EB5E00',
    created: '#00B0E4',
    new: '#10D6E5',
  },
  typo: {
    default: '#E4E7EB',
    text: '#8D96B0',
    secondary: '#E4E7EB',
    icon: '000000',
  },
  green: {
    green1: '#0FC286',
    green2: '#02B175',
    green3: '#244443',
    green4: '#4caf50',
    green5: '#0A875D',
  },
  grey: {
    grey1: '#7A9091',
    grey2: '#4A5368',
    grey3: '#3F3F3F',
    grey5: '#E6E7E8',
    grey6: '#DAE1E4',
    grey7: '#F5F5F5',
    grey8: '#424242',
    grey9: '#7373734D',
    grey12: '#E0E0E0',
    grey11: '#BFC5D0',
    grey13: '#828A96',
    grey14: '#99A1AD',
    grey15: '#808896',
    grey16: '#6C6E73',
  },
  purple: {
    purple1: '#658AFF',
    purple2: '#374C80',
    purple3: '#22335C',
    purple4: '#3B225C',
    purple5: '#A790FF',
  },
  orange: {
    orange1: '#FF8D1C',
    orange2: '#FFAA56',
    orange3: '#FF9518',
  },
  yellow: {
    yellow1: '#FAFA33',
  },
  blue: {
    blue1: '#658AFF',
    blue3: '#374C80',
    blue2: '#22335C',
    blue4: '#3F3F3F',
    blue5: '#3a5a94',
    blue6: '#39E2C9',
    blue7: '#39E2C9',
    hover: '#33CBB4',
  },
  red: {
    red1: '#DE5D67',
    red2: '#FF5142',
    red4: '#FF5142',
    red5: '#DE5D57',
    red6: '#702B3E',
    red9: '#C32519',
    hover: '#FF8F82',
  },
  black: {
    black1: '#3F3F3F',
    black2: '#383838',
    black3: '#2B2B2B',
    black4: '#0B0B0BCC',
    black5: '#2B2B2B61',
    black6: '#0000004D',
  },
  white: {
    white1: '#E4E7EB',
    white2: '#EEEFF1',
  },
}

export default theme
