import React, { useEffect, useRef, memo } from 'react'
import { Grid, Container } from './TradingView.style'
import { useSelector } from 'react-redux'
import LoadingOverlay from '../NotificationOverlays/LoadingOverlay'

function TradingView() {
  const container = useRef()
  const product = useSelector((state) => state?.tradingView?.product)

  const getSymbol = () => {
    if (product) {
      return `BITSTAMP:${product.replaceAll('-', '')}`
    } else {
      return 'BITSTAMP:BTCUSD'
    }
  }

  useEffect(() => {
    const chart = document.getElementById('chart_id')
    if (chart) {
      chart.remove()
    }
    const script = document.createElement('script')
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js'
    script.type = 'text/javascript'
    script.async = true
    script.id = 'chart_id'
    script.innerHTML = `
        {
          "autosize": true,
          "symbol": "${getSymbol()}",
          "interval": "1",
          "timezone": "Etc/UTC",
          "theme": "dark",
          "style": "1",
          "locale": "en",
          "enable_publishing": false,
          "backgroundColor": "#2B2B2B",
          "allow_symbol_change": false,
          "calendar": false
        }`
    container.current.appendChild(script)
  }, [product])
  return (
    <>
      {product ? (
        <Container>
          <Grid className="tradingview-widget-container" ref={container} />
        </Container>
      ) : (
        <LoadingOverlay height={'calc(100% - 34px)'} />
      )}
    </>
  )
}

export default memo(TradingView)
