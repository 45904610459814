import { Grid } from '@mui/material'
import React from 'react'
import { CtErrorOverlayTextBox, ErrorMsg, CtOverlay } from './Error.style'
import clsx from 'clsx'

function Error(props) {
  const { productObj, dataFromWS, exceededMin, exceededMax, exceededMaxQtyDecimals, authorizedMax, authorizedMin, decimalsOfQuantity } = props
  const CtOverlayClasses = clsx({
    CtErrorOverlay: !(dataFromWS?.filter && !dataFromWS?.price),
  })

  return (
    (exceededMin || exceededMax || exceededMaxQtyDecimals || (dataFromWS && !dataFromWS?.price) || !productObj || productObj.symbol === '') && (
      <Grid container justifyContent="center" style={{ position: 'relative' }}>
        <CtOverlay item xs={12} className={CtOverlayClasses}></CtOverlay>
        <CtErrorOverlayTextBox item xs={12}>
          {dataFromWS && !dataFromWS?.price && <ErrorMsg>{`Prices for this product are not available now`}</ErrorMsg>}
          {(!productObj || productObj.symbol === '') && <ErrorMsg>{`Please choose a product`}</ErrorMsg>}
          {exceededMax && <ErrorMsg>{`Maximum authorized quantity is ${authorizedMax}`}</ErrorMsg>}
          {exceededMin && <ErrorMsg>{`Minimum authorized quantity is ${authorizedMin}`}</ErrorMsg>}
          {exceededMaxQtyDecimals && <ErrorMsg>Maximum {decimalsOfQuantity} decimals allowed</ErrorMsg>}
        </CtErrorOverlayTextBox>
      </Grid>
    )
  )
}

export default Error
