import React from 'react'
import { Grid, Tooltip } from '@mui/material'
import { ClickTradingSmallTxt, SlippageContainer, SlippageInnerContainer, Bps } from './SlippageAndType.style.js'
import SelectWithArrow from '../SharedComponents/SelectWithArrow/SelectWithArrow.jsx'
import CustomInput from '../SharedComponents/CustomInput/CustomInput.jsx'

function SlippageAndType({ slippage, timeInForce, setTimeInForce, orderType, setOrderType, handleChangeSlippage, widgetType, productObj }) {
  const typesTIF = [
    { name: 'FOK', val: 'FOK' },
    { name: 'GTC', val: 'GTC' },
  ]

  const typesBasic = [
    { name: 'MKT', val: 'market' },
    { name: 'FOK', val: 'limit' },
  ]

  const typesAdvanced = [
    { name: 'Market', val: 'market' },
    { name: 'Market-Nominal', val: 'market-nominal' },
    { name: 'Limit', val: 'limit' },
  ]

  const onChangeHandler = (e) => {
    setOrderType(e.target.value)
  }

  const getItems = (widgetType) => {
    return widgetType === 'basic' ? typesBasic : typesAdvanced
  }
  return (
    <Grid container justifyContent="center" style={{ marginTop: 18 }}>
      <Grid item xs={12}>
        <ClickTradingSmallTxt container alignItems="center" justifyContent="space-between">
          <SlippageContainer item xs={widgetType === 'advanced' ? 3 : 6} id="slippage-container-id" style={{ paddingRight: 8 }}>
            <SlippageInnerContainer container alignItems="center" justifyContent="space-between">
              <Grid item xs={12}>
                <Tooltip title="min qty : 0 , max qty: 20" style={{ zIndex: 999 }}>
                  <Grid container alignItems="center">
                    <CustomInput
                      label={'Slippage'}
                      value={slippage}
                      onChange={(e) => handleChangeSlippage(e.target.value)}
                      placeholder={''}
                      disabled={['market-nominal', 'market'].includes(orderType)}
                      inputType={'number'}
                      // error={disableTrading}
                      InputProps={{
                        style: { height: '100%', padding: 0 },
                        min: 0,
                        endAdornment: <Bps color="secondary">bps</Bps>,
                      }}
                    />
                  </Grid>
                </Tooltip>
              </Grid>
            </SlippageInnerContainer>
          </SlippageContainer>
          <SlippageContainer item xs={6} id="slippage-container-id" style={{ paddingLeft: 8 }}>
            {/* <Grid container alignItems="center" justifyContent="center"> */}
            <SelectWithArrow orderType={orderType} onChange={onChangeHandler} disabled={!productObj} items={getItems(widgetType)} label={'Type'} />
            {/* </Grid> */}
          </SlippageContainer>
          {widgetType === 'advanced' && (
            <SlippageContainer item xs={3} id="slippage-container-id" style={{ paddingLeft: 11 }}>
              <Grid container alignItems="center" justifyContent="center">
                <SelectWithArrow
                  orderType={timeInForce}
                  onChange={(e) => setTimeInForce(e.target.value)}
                  disabled={['market-nominal', 'market'].includes(orderType)}
                  items={typesTIF}
                />
              </Grid>
            </SlippageContainer>
          )}
        </ClickTradingSmallTxt>
      </Grid>
    </Grid>
  )
}

export default React.memo(SlippageAndType)
