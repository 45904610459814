import { styled } from '@mui/system'
import SearchIcon from '@mui/icons-material/Search'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, Paper, Autocomplete as AutocompleteBase } from '@mui/material'

export const StyledSearchIcon = styled(SearchIcon)(({ theme }) => ({
  color: `${theme.palette.blue.blue7}`,
  marginLeft: '10px',
  marginRight: '6px',
}))

export const StyledArrowIcon = styled(ExpandMoreIcon)(({ theme }) => ({
  color: `${theme.palette.blue.blue7} !important`,
  marginLeft: '2px',
  marginRight: '6px',
}))

export const StyleAutoCompleteMenuItem = styled(Box)(({ theme }) => ({
  backgroundColor: `${theme.palette.black.black2} !important`,
  color: 'white',
  border: `0.5px solid ${theme.palette.grey.grey8}`,
  '&:hover': {
    backgroundColor: `${theme.palette.black.black2} !important`,
  },
}))

export const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.black.black2,
  overflow: 'hidden',
}))

export const StyledListBox = styled('ul')(({ theme }) => ({
  backgroundColor: theme.palette.black.black2,
  overflow: 'auto',
  height: '169px',
  '&::-webkit-scrollbar': {
    width: '6px',
    height: '6px',
  },
  '&::-webkit-scrollbar-track': {
    background: theme.palette.black.black1,
    width: '6px',
    height: '6px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.primary.background,
    borderRadius: '4px',
    width: '6px',
    height: '6px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: theme.palette.primary.background,
    width: '6px',
    height: '6px',
  },
}))

export const Autocomplete = styled(AutocompleteBase)(({ theme, disabled }) => ({
  width: '100%',
  '& .MuiOutlinedInput-input': {
    cursor: 'pointer',
    '&:disabled': {
      cursor: 'default',
    },
  },
  '& .MuiInputBase-root': {
    height: '40px',
    width: '100%',
    padding: '0px',
  },
  '& .MuiFormControl-root': {
    width: '100%',
    backgroundColor: theme.palette.black.black1,
    borderRadius: '4px',
  },
  '& .MuiInputBase-root.Mui-disabled': {
    backgroundColor: theme.palette.black.black3,
    '& > fieldset': {
      borderColor: theme.palette.grey.grey3,
    },
  },
  '& .MuiSvgIcon-root': {
    color: disabled ? theme.palette.grey.grey1 : theme.palette.blue.blue7,
  },
  '& .MuiFormLabel-root': {
    color: `${theme.palette.grey.grey1} !important`,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.black.black1,
  },
  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: theme.palette.grey.grey1,
  },
}))
