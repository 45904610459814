import SnackbarContent from '@mui/material/SnackbarContent'
import { styled } from '@mui/system'

export const SnackbarContentStyled = styled(SnackbarContent)(({ type, theme }) => {
  let backgroundColor = theme.palette.green.green4
  switch (type) {
    case 'error':
      backgroundColor = theme.palette.red.red5
      break
    case 'success':
      backgroundColor = theme.palette.green.green4
      break
  }
  return {
    color: 'white',
    fontSize: theme.typography.fontSizes.m,
    backgroundColor,
    width: 'fit-content',
    textTransform: 'capitalize',
  }
})
