const typography = {
  fontFamily: `${['Inter', 'sans-serif', 'Roboto Mono', 'monospace'].join(',')}`,
  // fontSize: 14,
  // fontSizes: {
  //   xxxb: 34,
  //   xxb: 30,
  //   xb: 32,
  //   b: 28,
  //   xxxl: 24,
  //   xxl: 22,
  //   xl: 20,
  //   lg: 18,
  //   m: 16,
  //   s: 13,
  //   xs: 12,
  //   xxs: 10,
  // },
  // fontSize: 12,
  // fontSizes: {
  //   xxxb: 32,
  //   xxb: 28,
  //   xb: 30,
  //   b: 26,
  //   xxxl: 22,
  //   xxl: 20,
  //   xl: 18,
  //   lg: 16,
  //   m: 14,
  //   s: 11,
  //   xs: 10,
  //   xxs: 8,
  // },
  fontSize: 11,
  fontSizes: {
    t: 34,
    xxxb: 31,
    xxb: 27,
    xb: 29,
    b: 25,
    sb: 21,
    xxxl: 19,
    xxl: 18,
    xl: 17,
    lg: 15,
    m: 13,
    s: 10,
    xs: 9,
    xxs: 7,
  },
}

export default typography
