import { Button, Grid, Typography } from '@mui/material'
import { styled } from '@mui/system'

export const ButtonsContainer = styled(Grid)(() => ({
  marginTop: 16,
}))

export const ClickTradingDiffTxt = styled(Typography)(({ theme }) => ({
  color: theme.palette.typo.default,
  backgroundColor: theme.palette.primary.main,
  fontSize: theme.typography.fontSizes.lg,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '5px 0',
  borderRadius: '4px 4px 0 0',
  minHeight: 24,
  lineHeight: 1.8,
}))

export const ClickTradingDiffRelative = styled(Grid)(() => ({
  position: 'relative',
}))

export const ClickTradingDiffAbsolute = styled(Grid)(() => ({
  justifyContent: 'center',
  position: 'absolute',
  bottom: 5,
}))

export const PricesContainer = styled(Grid)(() => ({
  marginBottom: '6px',
  position: 'relative',
  height: 100,
}))

export const CustomBuyBottom = styled(Button)(({ theme }) => ({
  height: '100px',
  color: theme.palette.primary.main,
  borderRadius: '4px',
  backgroundColor: theme.palette.green.green1,
  boxShadow: 'none',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  border: `3px solid ${theme.palette.green.green1}`,
  '&:hover': {
    backgroundColor: theme.palette.green.green1,
    boxShadow: 'none',
  },
  '&:disabled': {
    backgroundColor: theme.palette.green.green5,
    color: theme.palette.primary.main,
    border: 'none',
  },
  '&.MuiButton-root': {
    padding: '8px 0',
  },
  '&.ButtonBuyAdvanced': {
    background: `${theme.palette.black.black3} 0% 0% no-repeat padding-box`,
    border: `3px solid ${theme.palette.green.green1}`,
    // '&::after': {
    //   content: '""',
    //   width: '80px',
    //   height: '33%',
    //   borderRight: `3px solid ${theme.palette.green.green1}`,
    //   borderTop: `3px solid ${theme.palette.green.green1}`,
    //   borderRadius: '4px 0px 0px 0px',
    //   position: 'absolute',
    //   right: '64%',
    //   bottom: 0,
    // },
  },
}))

export const CustomSellBottom = styled(Button)(({ theme }) => ({
  height: '100px',
  position: 'relative',
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.red.red1,
  borderRadius: '4px',
  boxShadow: 'none',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  border: `3px solid ${theme.palette.red.red1}`,
  '&:hover': {
    backgroundColor: theme.palette.red.red1,
    boxShadow: 'none',
  },
  '&:disabled': {
    backgroundColor: theme.palette.red.red6,
    color: theme.palette.primary.main,
    border: 'none',
  },
  '&.MuiButton-root': {
    padding: '8px 0',
  },
  '&.ButtonSellAdvanced': {
    background: `${theme.palette.black.black3} 0% 0% no-repeat padding-box`,
    border: `3px solid ${theme.palette.red.red1}`,
    // '&::after': {
    //   content: '""',
    //   width: '80px',
    //   height: '33%',
    //   borderLeft: `3px solid ${theme.palette.red.red1}`,
    //   borderTop: `3px solid ${theme.palette.red.red1}`,
    //   borderRadius: '4px 0px 0px 0px',
    //   position: 'absolute',
    //   left: '64%',
    //   bottom: 0,
    // },
  },
}))

export const ClickTradingDecimal = styled(Typography)(({ theme }) => ({
  marginTop: '-2px',
  fontSize: theme.typography.fontSizes.xxl,
  fontWeight: 400,
  '&.ClickTradingBidPrice': {
    textAlign: 'left',
    paddingLeft: '10px',
  },
  '&.NumbersFontBidAdvanced': {
    color: theme.palette.red.red1,
  },
  '&.ClickTradingAskPrice': {
    textAlign: 'right',
    paddingRight: '10px',
  },
  '&.NumbersFontAskAdvanced': {
    color: theme.palette.green.green1,
  },
}))

export const ClickTradingPrice = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  paddingLeft: '10px',
  fontWeight: 'bold',
  fontSize: theme.typography.fontSizes.t,
  '&.ClickTradingBidPrice': {
    textAlign: 'left',
    paddingLeft: '10px',
  },
  '&.NumbersFontBidAdvanced': {
    color: theme.palette.red.red1,
  },
  '&.ClickTradingAskPrice': {
    textAlign: 'right',
    paddingRight: '10px',
  },
  '&.NumbersFontAskAdvanced': {
    color: theme.palette.green.green1,
  },
}))
