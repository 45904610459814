import { styled } from '@mui/system'
import { LoadingButton } from '@mui/lab'

export const StyledLoaderButton = styled(LoadingButton)(({ theme, width }) => ({
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.blue.blue7,
  fontSize: theme.typography.fontSizes.lg,
  fontWeight: 600,
  width: width || '100%',
  '&:hover': {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.blue.blue7,
  },
  '&:disabled': {
    color: theme.palette.grey.grey1,
    backgroundColor: 'transparent',
    boxShadow: `inset 0 0 0 1px ${theme.palette.primary.divider}`,
  },
  '.MuiCircularProgress-root': {
    height: '18px',
    width: '18px',
    color: 'white',
  },
}))
