import React, { useEffect, useRef, memo } from 'react'
import { Grid } from './TickerTape.style'

function TickerTape() {
  const container = useRef()

  useEffect(() => {
    const chart = document.getElementById('ticker_tape_id')
    if (chart) {
      chart.remove()
    }
    const script = document.createElement('script')
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js'
    script.type = 'text/javascript'
    script.async = true
    script.id = 'ticker_tape_id'
    script.innerHTML = `
    {
      "symbols": [
        {
          "description": "BTC-USD",
          "proName": "BITSTAMP:BTCUSD"
        },
        {
          "description": "ETH-USD",
          "proName": "BITSTAMP:ETHUSD"
        },
        {
          "description": "BTC-EUR",
          "proName": "COINBASE:BTCEUR"
        },
        {
          "description": "ETH-EUR",
          "proName": "COINBASE:ETHEUR"
        },
        {
          "description": "USDT-USD",
          "proName": "COINBASE:USDTUSD"
        },
        {
          "description": "USDT-EUR",
          "proName": "COINBASE:USDTEUR"
        },
        {
          "description": "SOL-USD",
          "proName": "COINBASE:SOLUSD"
        },
        {
          "description": "SHIB-USD",
          "proName": "COINBASE:SHIBUSD"
        },
        {
          "description": "MATIC-USD",
          "proName": "COINBASE:MATICUSD"
        },
        {
          "description": "ADA-USD",
          "proName": "COINBASE:ADAUSD"
        },
        {
          "description": "AVAX-USD",
          "proName": "COINBASE:AVAXUSD"
        },
        {
          "description": "XLM-USD",
          "proName": "COINBASE:XLMUSD"
        }
      ],
      "showSymbolLogo": true,
      "isTransparent": true,
      "displayMode": "compact",
      "colorTheme": "dark",
      "locale": "en"
    }`
    container.current.appendChild(script)
  }, [])

  return <Grid className="tradingview-widget-container" ref={container} />
}

export default memo(TickerTape)
