import { Typography } from '@mui/material'
import { styled } from '@mui/system'

export const Vcontainer = styled(`div`)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  color: theme.palette.primary.success,
  fontSize: theme.typography.fontSizes.xs,
}))

export const CheckmarkContainer = styled(`div`)({
  width: 30,
  height: 30,
  position: 'relative',
  bottom: 8,
  left: '2px',
})

export const Checkmark = styled(`div`)(({ theme }) => ({
  transform: 'rotate(-55deg)',
  position: 'fixed',
  zIndex: 1,
  marginBottom: 10,
  animation: `$myEffect 300ms ${theme.transitions.easing.easeInOut}`,
  // animation: '.6s ease checkmark',
  /* animation-delay: 0.5s; */
  overflow: 'hidden',
  paddingBottom: 0,
  /* opacity: 0; */
}))

export const Vtext = styled(Typography)(({ theme }) => ({
  marginTop: 5,
  fontSize: theme.typography.fontSizes.lg,
}))
export const CheckMarkButton = styled(`button`)(({ theme }) => ({
  '&.Vspin': {
    height: 48,
    width: 48,
    '&::before': {
      border: '2px solid transparent',
    },
    '&::before, &::after': {
      top: 0,
      left: 0,
    },

    '&::after': {
      border: '0 solid transparent',
    },
  },
  '&.Vcircle': {
    borderRadius: '100%',
    boxShadow: 'none',
    '&::before, &::after': {
      borderRadius: '100%',
    },
  },
  '&.Vbutton': {
    border: 0,
    position: 'relative',
    outline: 'none',
    backgroundColor: 'transparent',
    transform: 'rotate(55deg)',
    zIndex: 0,
    '&::before, &::after': {
      boxSizing: 'inherit',
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
    },
  },
  '&.focusVspin': {
    '&::before': {
      borderRightColor: theme.palette.primary.success,
      borderBottomColor: theme.palette.primary.success,
      transition: 'border-top-color 0.15s linear, border-right-color 0.15s linear 0.10s, border-bottom-color 0.15s linear 0.20s',
    },
    '&::after': {
      borderTop: `2px solid ${theme.palette.primary.success}`,
      borderLeftWidth: 2,
      borderRightWidth: 2,
      transform: 'rotate(270deg)',
      transition: 'transform 0.4s linear 0s, border-left-width 0s linear 0.35s, -webkit-transform 0.4s linear 0s',
    },
  },
}))
