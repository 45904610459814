import React from 'react'
import { StyledFilterText, StyledResetText, StyledTitle } from '../../DrawerFilters.style.'
import { Box } from '@mui/material'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

import './DrawerFilterTradeBlotter.css'
import { DatePicker } from './DrawerFilterTradeBlotter.style.'

function DrawerFilterTradeBlotter({ from, setFrom }) {

  const handleChangeDate = (newValue) => {
    if (isNaN(newValue.valueOf())) { return }
    setFrom(newValue.valueOf());
  }

  const handleResetForm = () => {
    setFrom(dayjs.utc().startOf('month').valueOf());
  }

  return (
    <Box style={{ paddingLeft: '12px', paddingRight: '12px', paddingTop: '8px' }}>
      <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
        <StyledFilterText>Filters</StyledFilterText>
        <StyledResetText onClick={handleResetForm}>Reset</StyledResetText>
      </Box>
      <StyledTitle>Starting Date:</StyledTitle>
      <DatePicker value={dayjs.utc(from)} onChange={handleChangeDate} disableFuture={true} disableHighlightToday={true} format="YYYY-MM-DD" />
    </Box>
  )
}

export default DrawerFilterTradeBlotter
