import axios from 'axios'
import { createSlice } from '@reduxjs/toolkit'
import { REST_GATEWAY_URL, ENDPOINTS } from '../../../utils/constants'
import { handelNotifications } from '../Notification/notificationSlice'

export const exposuresSlice = createSlice({
  name: 'exposures',
  initialState: {
    exposures: [],
    exposureRequests: null,
  },
  reducers: {
    setExposures: (state, action) => {
      state.exposures = action.payload
    },
  },
})

export const getAllExposures = () => async (dispatch) => {
  try {
    const res = await axios.get(`${REST_GATEWAY_URL}${ENDPOINTS.EXPOSURE}`)
    if (res.status === 200) {
      dispatch(setExposures(res.data))
    }
  } catch (error) {
    dispatch(
      handelNotifications({
        id: Date.now(),
        message: `Could not get exposure`,
        type: 'error',
      })
    )
  }
}

export const { setExposures } = exposuresSlice.actions

export default exposuresSlice.reducer
