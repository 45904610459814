import { Grid, TableRow } from '@mui/material'
import Table from '@mui/material/Table'
import TableCell from '@mui/material/TableCell'
import { styled } from '@mui/system'

export const TableContainer = styled(Grid)(() => ({
  marginTop: 18,
  borderRadius: 4,
}))

export const StyledTable = styled(Table)(({ theme }) => ({
  backgroundColor: theme.palette.black.black2,
  borderRadius: 4,
  borderCollapse: 'separate',
  borderSpacing: 0,
  tableLayout: 'fixed',
  width: '100%',
}))

export const ClickTradingTableLight = styled(TableCell)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.primary.background}`,
  color: theme.palette.text.primary,
  width: '18%',
  padding: '6px 0',
}))

export const StyledTablePriceCell = styled(TableCell)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.primary.background}`,
  whiteSpace: 'nowrap',
  padding: '6px 0px',
  fontWeight: 500,
  fontSize: theme.typography.fontSizes.m,
  textAlign: 'center',
  letterSpacing: '-0.1px',
  '&.RedCell': {
    color: theme.palette.red.red1,
  },
  '&.GreenCell': {
    color: theme.palette.green.green1,
  },
}))

export const CustomTableRowHeader = styled(TableRow)(() => ({}))

export const StyledTableQtyGrid = styled(Grid)(({ theme }) => ({
  '&.LevelLabel': {
    textAlign: 'center',
    fontSize: theme.typography.fontSize,
  },
}))

export const CustomTableCellHeader = styled(TableCell)(({ theme }) => ({
  color: theme.palette.grey.grey1,
  borderBottom: `1px solid ${theme.palette.black.black1}`,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  padding: '6px 0px',
  fontWeight: 500,
  textAlign: 'center',
  letterSpacing: '-0.1px',
}))
