import React from 'react'
import { CtErrorOverlayUnavailable, CtLoadingOverlay } from './ErrorFeedbackOverlay.style'
import { Grid } from '@mui/material'
// import { capitalizeFirstLetter } from '../../../utils/helperFunctions'

function ErrorFeedbackOverlay(props) {
  return (
    <Grid container justifyContent="center">
      <CtErrorOverlayUnavailable style={{ height: props.height }} item xs={12}></CtErrorOverlayUnavailable>
      <CtLoadingOverlay variant="body2">{props.errorMessage}</CtLoadingOverlay>
    </Grid>
  )
}

export default ErrorFeedbackOverlay
