import axios from 'axios'
import { useEffect, useState } from 'react'
import { REST_GATEWAY_URL, WIDGETS, WIDGETS_ID } from '../utils/constants'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { logout } from '../redux/slices/Auth/authSlice'
import { handelNotifications } from '../redux/slices/Notification/notificationSlice'
import { isEqual } from 'lodash'

function useAxios({ endPoint, method, params, widget, setLoading }, options = {}) {
  const [response, setResponse] = useState({})
  const dispatch = useDispatch()
  const url = `${REST_GATEWAY_URL}${endPoint}`
  const navigate = useNavigate()
  const token = useSelector((state) => state.auth.token)
  const widgetRefresh = useSelector((state) => state.utils.reloadDataByWidget[widget])
  const [oldParams, setOldParams] = useState(params)

  const requestData = async (userRequest = {}) => {
    const request = {
      method,
      url,
      headers: { Authorization: `Bearer ${token}` },
      params: userRequest.params || params,
    }
    try {
      setLoading(true)
      const apiResult = await axios.request(request)
      setResponse({ status: apiResult.status, data: apiResult.data })
      dispatch(
        handelNotifications({
          id: Date.now(),
          message: `${widget.replaceAll('_', ' ')} made successfully`,
          type: 'success',
        })
      )
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login')
        dispatch(logout())
        dispatch(
          handelNotifications({
            id: Date.now(),
            message: `Please Login`,
            type: 'error',
          })
        )

        return
      }
      dispatch(
        handelNotifications({
          id: Date.now(),
          message: `Failed to get ${widget.replace('_', ' ')} data`,
          type: 'error',
        })
      )
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    requestData()
  }, [])

  useEffect(() => {
    if (widgetRefresh === true) {
      if (![WIDGETS.BALANCE_MOVEMENT, WIDGETS.TRADES_BLOTTER].includes(widget)) {
        requestData()
      } else if ([WIDGETS.BALANCE_MOVEMENT, WIDGETS.TRADES_BLOTTER].includes(widget)) {
        const id = `table_container_${WIDGETS_ID.TRADES_BLOTTER}`
        const table = document.getElementById(id)
        if (table) {
          table.scrollTo(0, 0)
        }
        if ('resetState' in options) {
          for (const resetState of options.resetState) {
            resetState()
          }
        }
        requestData({ params: { ...params, count: 20, page: 1 } })
      }
    }
  }, [widgetRefresh])

  useEffect(() => {
    /// else scroll bar action happen
    if (params && params.page === 1 && !isEqual(oldParams, params)) {
      requestData(params)
    }
    if (!isEqual(oldParams, params)) {
      setOldParams(params)
    }
  }, [params])

  return [response, setResponse]
}

export default useAxios
