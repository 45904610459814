import { styled } from '@mui/system'
import { Grid, Typography } from '@mui/material'

export const ErrorMsg = styled(Typography)(({ theme }) => ({
  color: theme.palette.red.red4,
  fontWeight: 600,
  letterSpacing: '0.5px',
  textAlign: 'center',
}))

export const CtErrorOverlayTextBox = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.primary.background,
  position: 'absolute',
  // top: 120,
  top: 50,
  zIndex: 11,
  padding: '4px 6px',
  borderRadius: 4,
  maxWidth: '80%',
  marginLeft: '3px',
  border: `2px solid ${theme.palette.red.red4}`,
}))

export const CtOverlay = styled(Grid)(({ theme }) => ({
  '&.CtErrorOverlay': {
    position: 'absolute',
    width: '100%',
    left: 0,
    height: 100,
    background: `${theme.palette.black.black5} 0% 0% no-repeat padding-box`,
    zIndex: 10,
    backdropFilter: 'blur(10px)',
    borderRadius: 4,
    // marginTop: 80,
    marginTop: 16,
  },
}))
