import React from 'react'
import clsx from 'clsx'
import { Grid, Typography } from '@mui/material'

import { formatNumber } from '../../../utils/helperFunctions'
import { OverlayContainer, ErrorMessage, WhiteColorText, ValueContainer, TradeAction } from './ActionFeedbackOverlay.style'
import AnimatedSuccess from '../AnimatedSuccess'
import AnimatedFail from '../AnimatedFail'

function ActionFeedbackOverlay({ showAnimation, setShowOverlayAnimation }) {
  const { type, side, quantity, product, price, tradeId, nominal, noValues } = showAnimation

  const TradeActionCondition = clsx({
    tradeActionSell: side === 'sell',
    tradeActionBuy: side === 'buy',
  })

  const simpleAnimation = () => (
    <Grid container style={{ marginTop: 24 }}>
      <Grid item xs={12} container>
        <Grid item xs={6} container>
          <Typography color="secondary">Side:</Typography>
          <TradeAction className={TradeActionCondition}> {side}</TradeAction>
        </Grid>
        <Grid item xs={6} container>
          <Typography color="secondary">Quantity:</Typography>
          <WhiteColorText> {quantity}</WhiteColorText>
        </Grid>
      </Grid>
      <Grid item xs={12} container style={{ marginTop: 8 }}>
        <Grid item xs={6} container>
          <Typography color="secondary">Product:</Typography>
          <ValueContainer> {product}</ValueContainer>
        </Grid>
        {price && (
          <Grid item xs={6} container>
            <Typography color="secondary">Price:</Typography>
            <ValueContainer> {formatNumber(price, 'notWidget', 4)}</ValueContainer>
          </Grid>
        )}
      </Grid>
      {nominal && (
        <Grid item xs={12} container style={{ marginTop: 8 }}>
          <Typography color="secondary">Nominal:</Typography>
          <ValueContainer> {nominal}</ValueContainer>
        </Grid>
      )}
      {tradeId && (
        <Grid item xs={12} container style={{ marginTop: 8 }}>
          <Typography color="secondary" className={'tradeId'}>
            Trade ID:
          </Typography>
          <ValueContainer className={tradeId}> {tradeId}</ValueContainer>
        </Grid>
      )}
    </Grid>
  )

  return (
    <OverlayContainer
      container
      justifyContent="center"
      alignItems="center"
      style={{
        display: showAnimation ? 'flex' : 'none',
        height: '100%',
      }}
    >
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            {(type === 'success' && <AnimatedSuccess setShowOverlayAnimation={setShowOverlayAnimation} />) || (type === 'fail' && <AnimatedFail />)}
          </Grid>
        </Grid>
        {!noValues ? simpleAnimation() : <ErrorMessage>Error has occurred, please try again</ErrorMessage>}
      </Grid>
    </OverlayContainer>
  )
}

export default ActionFeedbackOverlay
