import { styled } from '@mui/system'
import { Typography, Divider, Paper, Input } from '@mui/material'

export const ModalContent = styled(Paper)(({ theme }) => ({
  width: '552px',
  background: `${theme.palette.black.black2} 0% 0% no-repeat padding-box`,
  paddingLeft: '32px',
  paddingRight: '32px',
  paddingBottom: '24px',
}))

export const ModalHeader = styled(Typography)(({ theme }) => ({
  padding: '24px 0',
  textAlign: 'center',
  color: theme.palette.grey.grey1,
  fontSize: theme.typography.fontSizes.xl,
}))

export const ModalHeaderDivider = styled(Divider)(({ theme }) => ({
  backgroundColor: theme.palette.grey.grey1,
  marginBottom: '10px',
}))

export const Title = styled(Typography)({
  marginTop: '24px',
  color: 'white',
  marginBottom: '12px',
})

export const Article = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey.grey1,
  marginTop: '12px',
  marginBottom: '10px',
}))

export const ErrorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.red.red2,
  marginBottom: '30px',
}))

export const CostumeInput = styled(Input)(({ theme }) => ({
  '&&': {
    backgroundColor: theme.palette.black.black1,
    padding: '5px',
    border: 'none',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& input': {
      color: theme.palette.white.white1, // Set the text color
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },
  },
  '& input:-webkit-autofill': {
    color: theme.palette.white.white1,
    WebkitBoxShadow: `0 0 0 1000px ${theme.palette.grey.grey3} inset`,
  },
  '& .MuiInputLabel-root': {
    color: theme.palette.white.white1,
  },
  '& .MuiInputLabel-outlined': {
    transform: 'translate(14px, 12px) scale(1)',
  },
  '& .MuiInputLabel-shrink': {
    transform: 'translate(14px, -6px) scale(0.75)',
  },
  '& input::placeholder': {
    color: theme.palette.grey.grey1,
    opacity: 1,
  },
  '& .MuiInputBase-input::-webkit-input-placeholder': {
    opacity: 1,
  },
}))
