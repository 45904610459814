import React from 'react'
import { TableHead, Table, TableBody } from '@mui/material'
import { CustomTableContainer, CustomTableRowHeader, CustomTableCellHeader } from './SimpleTable.style'
import TableBodyRow from './components/TableBodyRow/TableBodyRow'

function SimpleTable(props) {
  const { rows, columns, id, onScrollHandel = () => {}, isopendrawerfilter = false } = props

  const element = document.getElementById(id)
  return (
    <CustomTableContainer height={element?.style?.height} isopendrawerfilter={String(isopendrawerfilter)} onScroll={onScrollHandel} id={`table_container_${id}`}>
      <Table aria-label="sticky table" size="small">
        <TableHead>
          <CustomTableRowHeader>
            {columns.map((c, i) => (
              <CustomTableCellHeader key={i} align="center">
                {c}
              </CustomTableCellHeader>
            ))}
          </CustomTableRowHeader>
        </TableHead>

        <TableBody>
          <TableBodyRow rows={rows} />
        </TableBody>
      </Table>
    </CustomTableContainer>
  )
}

export default React.memo(SimpleTable)
